import React, {Component} from "react";
import {connect} from "react-redux";
import './../Styles/styles.scss';

class InputCheckbox extends Component {
    render() {
        return (
            <div className={'input--checkbox'}>
                <input {...this.props} type={'checkbox'} onChange={(e) => this.onChange(e)}/>
                <label htmlFor={this.props.name}>{this.props.label}</label>
            </div>
        )
    }

    onChange = (e) => {
        this.props.onChange(this.props.name,e.target.checked)
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(InputCheckbox);