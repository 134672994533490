import React, {Component} from "react";
import {connect} from "react-redux";

class Piano extends Component {
    render() {
        let Octaves = [0,1,2,3,4,5,6,7,8];

        let Notes = [
            'C',
            'C#/Db',
            'D',
            'D#/Eb',
            'E',
            'F',
            'F#/Gb',
            'G',
            'G#/Ab',
            'A',
            'A#/Bb',
            'B',
        ];

        return (
            <div className={'piano_container'}>
                <div className="piano">
                    <div className="keys_container">
                        {Octaves.map(octave => (
                            Notes.map(key => (
                                    <div className={key.includes('#') ? 'key key--black' : 'key key--white'}></div>
                                ))
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Piano);