import React, {Component} from "react";
import {connect} from "react-redux";
import Translations from "../Translations";

class Impressum extends Component {
    render() {
        return (
            <div className="policy_div">
                <div className={'policy'}>
                    <h2>{Translations.impressum[this.props.userData.Language]}</h2>
                    Alexander Hinterleitner<br/>
                    Eisengöring 10<br/>
                    AT-5131 Franking<br/>
                    +43 660 46 55 845<br/>
                    contact@alexhinterleitner.com
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.userData,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps,mapDispatchToProps)(Impressum);