let localhost = false;
if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    localhost = true;
}

let test = false;
if (localhost || window.location.hostname === 'test.alexhinterleitner.com') {
    test = true;
}

const config = {
    baseURL      : localhost ? 'http://localhost/' : test ? 'https://test.alexhinterleitner.com/' : 'https://alexhinterleitner.com/',
    serverBaseURL: localhost ? 'http://localhost:3000' : test ? 'https://test.alexhinterleitner.com' :  'https://alexhinterleitner.com',
    audioURL     : 'homepage/src/Base/Media/Audio/',
    imageURL     : 'homepage/src/Base/Media/Images/',
    isLocalhost  : localhost,
    isTest       : test,
    isMobile     : {
        Android   : function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS       : function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera     : function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows   : function () {
            return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
        },
        any       : function () {
            return (config.isMobile.Android() || config.isMobile.BlackBerry() || config.isMobile.iOS() || config.isMobile.Opera() || config.isMobile.Windows());
        }
    },
    age          : function () {
        let dob = new Date('1996-03-26');
        let today = new Date();
        let age = today.getFullYear() - dob.getFullYear();
        let monthDiff = today.getMonth() - dob.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
            return age - 1;
        }
        return age;
    },
    wss          : test ? 'wss://wss.alexhinterleitner.com:3002' : 'wss://wss.alexhinterleitner.com:3001',
}

export default config;