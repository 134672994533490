import {createReducer, createAction} from "@reduxjs/toolkit";

//actions

export const togglePlayer = createAction('TOGGLE_PLAYER');
export const setSong = createAction('SET_SONG');
export const unsetReplay = createAction('UNSET_REPLAY');
export const togglePlayPause = createAction('TOGGLE_PLAY_PAUSE');
export const toggleFullView = createAction('TOGGLE_FULL_VIEW');
export const setRepeat = createAction('SET_REPEAT');

//initialState

export const musicPlayer = {
    open   : false,
    Album_ID: '',
    Song_ID: '',
    play   : false,
    replay : false,
    fullView: false,
    repeat: 0,
}

//reducer

const MusicPlayerReducer = createReducer(musicPlayer, builder => {
    builder
        .addCase(togglePlayer, (state) => {
            state.open = !state.open;
        })
        .addCase(setSong, (state, action) => {
            if(!state.open) {
                state.open = true;
            }
            if (action.payload.Song_ID === state.Song_ID) {
                state.replay = true;
            } else {
                state.Album_ID = action.payload.Album_ID;
                state.Song_ID = action.payload.Song_ID;
            }
            state.play = true;
        })
        .addCase(unsetReplay, (state, action) => {
            state.replay = false;
        })
        .addCase(togglePlayPause, (state, action) => {
            state.play = action.payload;
        })
        .addCase(toggleFullView, (state) => {
            state.fullView = !state.fullView;
        })
        .addCase(setRepeat, (state) => {
            state.repeat = state.repeat < 2 ? state.repeat+1 : 0;
        })
})

export default MusicPlayerReducer;