import {connect} from "react-redux";
import './bawe_notifications.scss';
import {useEffect} from "react";
import PropTypes from "prop-types";
import {loadData, sortObjectToReverseArray} from "../Base/Functions/Functions";
import {response} from "../Base/Modal/ModalReducer";
import {storeNotifications, storeBAWEList, setNotificationCount} from "./BAWEReducer";
import BAWENotificationElement from "./BAWENotificationElement";
import Translations from "../Base/Translations";

const BAWENotifications = (props) => {
    useEffect(() => {
        initial_load();
    }, [])

    const initial_load = () => {
        props.loadData({
            Query     : 'BAWE_load_notifications',
            title     : '',
            errorTitle: '',
            callBack  : props.storeNotifications,
            response  : props.response,
            hidden    : true,
        })
        props.loadData({
            Query     : 'loadBAWEList',
            title     : '',
            errorTitle: '',
            callBack  : props.storeBAWEList,
            response  : props.response,
            hidden    : true,
        })
        props.setNotificationCount({ count: 0 });
    }

    const sorted_notifications = sortObjectToReverseArray(props.notifications);

    return (
        props.notifications && Object.keys(props.notifications).length > 0 ?
            <div className={'bn_main'}>
                <div className="bn_main__list">
                    {props.notifications && sorted_notifications.length > 0 &&
                        sorted_notifications.map((item) => (
                            Object.keys(item).map((elem) => (
                                <BAWENotificationElement
                                    key={elem}
                                    listitem={item[elem]}
                                    language={props.language}
                                />
                            ))
                        ))
                    }
                </div>
            </div>
            :
            <div className={'bn_main__no_notifications'}>{Translations.noNotifications[props.language]}!</div>
    )
}

BAWENotifications.propTypes = {
    userID              : PropTypes.number,
    loadData            : PropTypes.func,
    response            : PropTypes.func,
    storeNotifications  : PropTypes.func,
    storeBAWEList       : PropTypes.func,
    language            : PropTypes.string,
    setNotificationCount: PropTypes.func,
}

const mapStateToProps = (state) => ({
    userID       : state.user.userData.userID,
    notifications: state.BAWE.notifications,
    language     : state.user.userData.Language,
})

const mapDispatchToProps = {
    loadData,
    response,
    storeNotifications,
    storeBAWEList,
    setNotificationCount,
}

export default connect(mapStateToProps, mapDispatchToProps)(BAWENotifications);