import React, {Component} from "react";
import {connect} from "react-redux";
import {setNewPlayCount} from "./MusicReducer";
import {setSong} from "./MusicPlayer/MusicPlayerReducer";
import {saveData} from "../Base/Functions/Functions";
import {response} from "../Base/Modal/ModalReducer";
import Translations from "../Base/Translations";
import {Audio} from "react-loader-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class SongContainer extends Component {
    render() {
        let songDuration = '';
        if (this.props.songData && this.props.songData.Song_Duration) {
            let min = Math.floor(this.props.songData.Song_Duration / 60);
            let sec = Math.floor((this.props.songData.Song_Duration / 60 - min) * 60);
            if(sec < 10) sec = '0' + sec;
            songDuration = min + ':' + sec;
        }

        return (
            <div className={'song_container'} onClick={this.setSong}>
                <div className={'song_container--title'}>
                    {this.props.songData.Album_ID === this.props.musicPlayer.Album_ID && this.props.songData.Song_ID === this.props.musicPlayer.Song_ID && this.props.musicPlayer.play &&
                        <Audio
                            height="20"
                            width="20"
                            color="orangered"
                            ariaLabel="audio-loading"
                            wrapperStyle={{}}
                            wrapperClass="wrapper-class"
                            visible={true}
                        />
                    }
                    <div className={'song_container--title--name'}>{this.props.songData.Song_Title}</div>
                </div>
                <div className={'song_container--count'}>
                    <FontAwesomeIcon icon={'play'} />
                    {this.props.songData.Play_Count}
                </div>
                <div className={'song_container--likes'}>
                    <FontAwesomeIcon icon={'heart'} />
                    {this.props.songData.Likes && Object.keys(this.props.songData.Likes).length ? Object.keys(this.props.songData.Likes).length : 0}
                </div>
                <div className={'song_container--duration'}>
                    {songDuration ? songDuration : this.state.songDuration}
                </div>
            </div>
        )
    }

    setSong = () => {
        document.title = 'Alex Hinterleitner | ' + this.props.songData.Song_Title;
        let fd = new FormData();
        let send_data = {
            Song_ID: this.props.songData.Song_ID,
        }
        fd.append('data', JSON.stringify(send_data));
        this.props.saveData({
            Query     : 'setSongCount',
            hidden    : true,
            formData  : fd,
            title     : Translations.songLoading[this.props.userData.Language],
            errorTitle: Translations.songLoadingError[this.props.userData.Language],
            callBack  : this.completeSetSongCount,
            response  : this.props.response,
        })

        this.props.setSong({
            Album_ID: this.props.songData.Album_ID,
            Song_ID : this.props.songData.Song_ID,
        });
    }

    completeSetSongCount = (data) => {
        this.props.setNewPlayCount({
            Album_ID: this.props.songData.Album_ID,
            Song_ID : this.props.songData.Song_ID,
            newCount: data.data.newCount,
        })
    }
}

const mapStateToProps = (state) => ({
    userData   : state.user.userData,
    songs      : state.songs,
    musicPlayer: state.musicPlayer,
})

const mapDispatchToProps = {
    saveData,
    response,
    setSong,
    setNewPlayCount,
}

export default connect(mapStateToProps, mapDispatchToProps)(SongContainer);