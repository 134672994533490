import React, {Component} from "react";
import {connect} from "react-redux";
import Piano from "./Piano";
import './musicians.scss';

class MusiciansContainer extends Component {
    render() {
        return (
            <div className={'musicians'}>
                <Piano />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps,mapDispatchToProps)(MusiciansContainer);