import Translations from "../Translations";
import {connect} from "react-redux";

const Select = (props) => {
    const onChange = (e) => {
        const sel_index = e.target.selectedIndex;
        const val = e.target[sel_index].value;
        props.onChange(props.name, Number(val));
    }

    return (
        <div className={props.className}>
            {props.label &&
                <label htmlFor={props.name}>{props.label}</label>
            }
            <select {...props} onChange={onChange}>
                <option value={null}>-- {Translations.chooseBundesland[props.language]} --</option>
                {Object.keys(props.options).map((item) => (
                    <option value={item}>{props.options[item][props.optionLabel]}</option>
                ))}
            </select>
        </div>
    )
}

const mapStateToProps = (state) => ({
    language: state.user.userData.Language,
})

export default connect(mapStateToProps,{})(Select);