import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {loadData} from "../Base/Functions/Functions";
import {response} from "../Base/Modal/ModalReducer";
import Translations from "../Base/Translations";
import Input from "../Base/Components/Input";

const TeslaGraz = (props) => {
    const [teslaList,setTeslaList] = useState({});
    const [kennzeichen,setKennzeichen] = useState('');
    const [color,setColor] = useState('');

    useEffect(() => {
        initialLoad();
    }, [])

    const initialLoad = () => {
        props.loadData({
            Query     : 'loadTeslaGraz',
            title     : 'Loading',
            errorTitle: Translations.errorOccured[props.userData.Language],
            callBack  : completeLoadTesla,
            response  : props.response,
        })
    }

    const completeLoadTesla = (data) => {
        setTeslaList(data);
    }

    return (
        <div style={{width: '100%'}}>
            <div style={{display: 'flex', width: '100%'}}>
                <Input
                    type={'text'}
                    name={'Kennzeichen'}
                    label={'Kennzeichen'}
                    placeholder={'Kennzeichen'}
                    onChange={(key,value) => setKennzeichen(value)}
                    value={kennzeichen}
                />
                <Input
                    type={'color'}
                    name={'Farbe'}
                    label={'Farbe'}
                    placeholder={'Farbe'}
                    onChange={(key,value) => setColor(value)}
                    value={color}
                />
            </div>
            <div style={{width: '100px', height: '100px', backgroundColor: color}}>{color}</div>
            <div>
                {Object.keys(teslaList).map((key) => (
                    <div>{teslaList[key].Kennzeichen}</div>
                ))}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    userData: state.user.userData,
})

const mapDispatchToProps = {
    loadData,
    response,
}

export default connect(mapStateToProps, mapDispatchToProps)(TeslaGraz)