import React, {Component} from "react";
import {connect} from "react-redux";
import config from "../Base/config";
import {setAlbumDetail} from "./MusicReducer";

class AlbumContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            detail: false,
            selectedAlbum: {},
        }
    }

    render() {
        return (
            <>
                <div className={this.props.albumDetail.open ? 'album_container_new album_container_new--fadeout' : 'album_container_new'} onClick={this.toggleAlbum}>
                    <img src={config.baseURL + config.imageURL + this.props.albumData.CoverImage} alt=""/>
                    <div className={'album_container_new--title'}>
                        {this.props.albumData.Album_Name}
                    </div>
                </div>
            </>
        )
    }

    toggleAlbum = () => {
        this.props.setAlbumDetail(this.props.albumData.Album_ID);
    }
}

const mapStateToProps = (state) => ({
    albumDetail: state.music.albumDetail,
})

const mapDispatchToProps = {
    setAlbumDetail
}

export default connect(mapStateToProps,mapDispatchToProps)(AlbumContainer);