import {createReducer, createAction} from "@reduxjs/toolkit";

//action

export const storeBAWEList = createAction('STORE_BAWE_LIST');
export const completeLike = createAction('COMPLETE_LIKE');
export const completeNewComment = createAction('COMPLETE_NEW_COMMENT');
export const completeDeleteComment = createAction('COMPLETE_DELETE_COMMENT');
export const completeAddDescription = createAction('COMPLETE_ADD_DESCRIPTION');
export const completeDeleteDescription = createAction('COMPLETE_DELETE_DESCRIPTION');
export const completeAddNewWord = createAction('COMPLETE_ADD_NEW_WORD');
export const completeDeleteWord = createAction('COMPLETE_DELETE_WORD');
export const storeNotifications = createAction('STORE_NOTIFICATIONS');
export const setNotificationCount = createAction('SET_NOTIFICATION_COUNT');
export const toggleLikeOverlay = createAction('TOGGLE_LIKE_OVERLAY');
export const toggleCheckNotifications = createAction('TOGGLE_CHECK_NOTIFICATIONS');
export const storeBAWEAuthors = createAction('STORE_BAWE_AUTHORS');
export const completeCommentLike = createAction('COMPLETE_COMMENT_LIKE');
export const togglePreventCommentScroll = createAction('TOGGLE_PREVENT_COMMENT_SCROLL');

//initialState

export const BAWE = {
    list                : {},
    notifications       : {},
    notification_count  : null,
    check_notifications : false,
    like_overlay        : {
        open: false,
        list: [],
    },
    authors             : {},
    quiz                : {
        word_id     : null,
        desc_options: [],
        desc_id     : null,
        word_options: [],
    },
    preventCommentScroll: false,
}

//reducer

const BAWEReducer = createReducer(BAWE, builder => {
    builder
        .addCase(storeBAWEList, (state, action) => {
            state.list = action.payload;
        })
        .addCase(completeLike, (state, action) => {
            if (action.payload.type === 0) {
                delete state.list[action.payload.Word_ID].Likes[action.payload.Like_ID];
            }
            if (action.payload.type === 1) {
                if (!state.list[action.payload.Word_ID].Likes) {
                    state.list[action.payload.Word_ID].Likes = {};
                }
                state.list[action.payload.Word_ID].Likes[action.payload.Like_ID] = {
                    Like_ID: action.payload.Like_ID,
                    User_ID: action.payload.User_ID,
                }
            }
        })
        .addCase(completeNewComment, (state, action) => {
            if (!state.list[action.payload.Word_ID].Comments) {
                state.list[action.payload.Word_ID].Comments = {};
            }
            state.list[action.payload.Word_ID].Comments[action.payload.Comment_ID] = {
                Comment_ID: action.payload.Comment_ID,
                User_ID   : action.payload.User_ID,
                Comment   : action.payload.Comment,
                datetime  : action.payload.datetime,
            };
        })
        .addCase(completeDeleteComment, (state, action) => {
            delete state.list[action.payload.Word_ID].Comments[action.payload.Comment_ID];
        })
        .addCase(completeAddDescription, (state, action) => {
            if (!state.list[action.payload.Word_ID].Description) {
                state.list[action.payload.Word_ID].Description = {};
            }
            state.list[action.payload.Word_ID].Description[action.payload.Description_ID] = {
                Description_ID: action.payload.Description_ID,
                Description   : action.payload.Description,
                datetime      : action.payload.datetime,
                User_ID       : action.payload.User_ID,
            };
        })
        .addCase(completeDeleteDescription, (state, action) => {
            delete state.list[action.payload.Word_ID].Description[action.payload.Description_ID];
        })
        .addCase(completeAddNewWord, (state, action) => {
            state.list[action.payload.Word_ID] = {
                Word_ID : action.payload.Word_ID,
                word    : action.payload.word,
                bad     : action.payload.bad,
                datetime: action.payload.datetime,
                User_ID : action.payload.User_ID,
            };
        })
        .addCase(completeDeleteWord, (state, action) => {
            delete state.list[action.payload.Word_ID];
        })
        .addCase(storeNotifications, (state, action) => {
            state.notifications = action.payload;
        })
        .addCase(setNotificationCount, (state, action) => {
            state.notification_count = action.payload.count;
        })
        .addCase(toggleLikeOverlay, (state, action) => {
            state.like_overlay.open = !state.like_overlay.open;
            if (action.payload && action.payload.user_likes_arr && action.payload.user_likes_arr.length > 0) {
                state.like_overlay.list = action.payload.user_likes_arr;
            }
        })
        .addCase(toggleCheckNotifications, (state, action) => {
            state.check_notifications = action.payload;
        })
        .addCase(storeBAWEAuthors, (state, action) => {
            state.authors = action.payload;
        })
        .addCase(completeCommentLike, (state, action) => {
            if (action.payload.type === 0) {
                delete state.list[action.payload.Word_ID].Comments[action.payload.Comment_ID].Comment_Likes[action.payload.Comment_Like_ID];
            }
            if (action.payload.type === 1) {
                if (!state.list[action.payload.Word_ID].Comments[action.payload.Comment_ID].Comment_Likes) {
                    state.list[action.payload.Word_ID].Comments[action.payload.Comment_ID].Comment_Likes = {};
                }
                state.list[action.payload.Word_ID].Comments[action.payload.Comment_ID].Comment_Likes[action.payload.Comment_Like_ID] = {
                    Comment_Like_ID: action.payload.Comment_Like_ID,
                    User_ID        : action.payload.User_ID,
                }
            }
            state.preventCommentScroll = true;
        })
        .addCase(togglePreventCommentScroll, (state, action) => {
            state.preventCommentScroll = action.payload;
        })
})

export default BAWEReducer;