import React, {Component} from "react";
import {connect} from "react-redux";
import './modal.scss';
import {InfinitySpin} from "react-loader-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {closeModal} from "./ModalReducer";
import Translations from "../Translations";
import {saveData} from "../Functions/Functions";
import {response} from "./ModalReducer";
import {storeUser} from "../UserReducer";

class ModalContainer extends Component {
    render() {
        return (
            <div className={'modal_container'}>
                <div className="modal">
                    <h2>{this.props.modal.title}</h2>
                    <p>{this.props.modal.text}</p>
                    {this.props.modal.type === 'error' ?
                        <>
                            <FontAwesomeIcon className={'error_icon'} icon={'times'}/>
                            <div className="error_close_icon" onClick={this.closeModal}>
                                <FontAwesomeIcon icon={'times'}/>
                            </div>
                        </>
                        :
                        <>
                            {!this.props.modal.edit &&
                                <InfinitySpin
                                    width='200'
                                    color="orangered"
                                />
                            }
                        </>
                    }
                    {this.props.modal.edit &&
                        <div className="modal--buttons">
                            <div
                                className={"modal--buttons--button modal--buttons--button--" + this.props.modal.confirmColor}
                                onClick={this.editConfirm}>{Translations.yes[this.props.userData.Language]}
                            </div>
                            <div
                                className={"modal--buttons--button modal--buttons--button--" + this.props.modal.cancelColor}
                                onClick={this.editCancel}>{Translations.no[this.props.userData.Language]}
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    closeModal = () => {
        this.props.closeModal();
    }

    editConfirm = () => {
        this.props.closeModal();
        if (this.props.modal.editType === 'deleteAccount') {
            let fd = new FormData();
            let send_data = {
                User_ID: this.props.userData.userID,
            }
            fd.append('data',JSON.stringify(send_data));
            this.props.saveData({
                Query     : 'deleteAccount',
                formData  : fd,
                title     : Translations.deletingAccount[this.props.userData.Language],
                errorTitle: Translations.failedDeletingAccount[this.props.userData.Language],
                callBack  : this.storeUser,
                response  : this.props.response,
            })
        }
    }

    editCancel = () => {
        this.props.closeModal();
    }

    storeUser = (data) => {
        this.props.storeUser(data.data);
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.userData,
    modal: state.modal,
})

const mapDispatchToProps = {
    saveData,
    response,
    storeUser,
    closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);