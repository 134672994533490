import React from "react";
import UserOverviewMenu from "./UserOverviewMenu";
import UserOverviewAccount from "./UserOverviewAccount";
import UserOverviewLanguage from "./UserOverviewLanguage";
import UserOverviewChangePassword from "./UserOverviewChangePassword";

const UserOverview = (props) => {
    return (
        <div className={'user_overview'}>
            <UserOverviewMenu setDetail={props.setDetail} userData={props.userData} logout={props.logout}/>
            <div className="user_overview--data">
                {props.detail === 'account' ?
                    <UserOverviewAccount userData={props.userData} deleteAccount={props.deleteAccount} />
                    :
                    props.detail === 'lang' ?
                        <UserOverviewLanguage userData={props.userData} changeLanguage={props.changeLanguage} />
                        :
                        props.detail === 'change_password' ?
                            <UserOverviewChangePassword userData={props.userData} />
                        :
                        null
                }
            </div>
        </div>
    )
}

export default UserOverview;