import React from "react";
import PropTypes from "prop-types";
import config from "../Base/config";

const LikeOverlayItem = (props) => {
    if(!props.userList[props.userID]) return null;

    return (
        <div className="like_overlay__container__main__list__elem">
            <div>
                {props.userList[props.userID].PreName + ' ' + props.userList[props.userID].LastName}
            </div>
            <img width={10}
                 src={config.baseURL + config.imageURL + '/AT_Flags/' + props.userList[props.userID].Flag_dir}
                 alt={props.userList[props.userID].Bundesland + ' Flagge'}
            />
        </div>
    )
}

LikeOverlayItem.propTypes = {
    userID  : PropTypes.number,
    userList: PropTypes.object,
}

export default LikeOverlayItem;