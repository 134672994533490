import Translations from "../Base/Translations";
import ReactCountryFlag from "react-country-flag";
import React from "react";

const UserOverviewLanguage = (props) => {
    return (
        <div className={'user_overview--data--language'}>
            <div className="user_overview--data--language--elems">
                <h3>{Translations.chooseLanguage[props.userData.Language]}:</h3>
                <ReactCountryFlag countryCode={'DE'}
                                  svg
                                  className={props.userData.Language === 'DE'
                                      ? 'user_overview--data--language--flag user_overview--data--language--flag--active'
                                      :
                                      'user_overview--data--language--flag'}
                                  onClick={() => props.changeLanguage('DE')}
                />
                <ReactCountryFlag countryCode={'GB'}
                                  svg
                                  className={props.userData.Language === 'EN'
                                      ? 'user_overview--data--language--flag user_overview--data--language--flag--active'
                                      :
                                      'user_overview--data--language--flag'}
                                  onClick={() => props.changeLanguage('EN')}
                />
            </div>
        </div>
    )
}

export default UserOverviewLanguage;