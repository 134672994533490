import {createReducer, createAction} from "@reduxjs/toolkit";

//actions

export const storeUser = createAction('STORE_USER');
export const editUserLogin = createAction('EDIT_USER_LOGIN');
export const userLogout = createAction('USER_LOGOUT');
export const setUserLanguage = createAction('SET_USER_LANGUAGE');
export const storeUserList = createAction('STORE_USER_LIST');
export const storeBundeslaender = createAction('STORE_BUNDESLAENDER');

//initialState

export const user = {
    userData     : {},
    userLogin    : {
        User_ID          : null,
        UserMail         : '',
        PreName          : '',
        LastName         : '',
        Password         : '',
        Bundesland       : null,
        BAWE_Notification: false,
        privacy_policy   : false,
    },
    userList     : {},
    Bundeslaender: {},
}

//reducer

const UserReducer = createReducer(user, builder => {
    builder
        .addCase(storeUser, (state, action) => {
            state.userData = action.payload;
            let lang = navigator.language.slice(0, 2).toUpperCase();
            if (!action.payload.Language) {
                state.userData.Language = lang === 'EN' || lang === 'DE' ? lang : 'EN';
            }
        })
        .addCase(editUserLogin, (state, action) => {
            state.userLogin[action.payload.key] = action.payload.value;
        })
        .addCase(userLogout, (state) => {
            let lang = navigator.language.slice(0, 2).toUpperCase();
            state.userData = {
                access    : 0,
                userRights: 0,
                Language  : lang,
            };
        })
        .addCase(setUserLanguage, (state, action) => {
            state.userData.Language = action.payload;
        })
        .addCase(storeUserList, (state, action) => {
            state.userList = action.payload;
        })
        .addCase(storeBundeslaender, (state, action) => {
            state.Bundeslaender = action.payload;
        })
})

export default UserReducer;