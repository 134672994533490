// Test.jsx
import React, {useEffect, useState} from 'react';

const Test = (props) => {
    const [inputMessage, setInputMessage] = useState('');

    const handleInputChange = (event) => {
        setInputMessage(event.target.value);
    };

    const handleSendClick = () => {
        props.socket.send(JSON.stringify({msg: inputMessage}));
    };

    return (
        <div>
            <h1>Nachricht von Websocket:</h1>
            <input type="text" value={inputMessage} onChange={handleInputChange}/>
            <button onClick={handleSendClick}>Nachricht senden</button>
        </div>
    );
};

export default Test;