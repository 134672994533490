import React, {Component} from "react";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {resetAlbumDetail} from "../MusicReducer";
import config from "../../Base/config";
import SongContainer from "../SongContainer";

class AlbumDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            detail: this.props.detail,
        }
    }

    render() {

        if(!this.props.albumData) {
            return null;
        }

        return (
            <div className={this.state.detail ? "album_detail album_detail--open" : "album_detail"}>
                <div className="album_detail--back">
                    <FontAwesomeIcon icon={'chevron-left'} onClick={this.back}/>
                </div>
                <div className={'album_container'}>
                    <div className={'album_container--header_div'}>
                        <div className="album_container--header" style={{backgroundImage: 'url(' + config.baseURL + config.imageURL + this.props.albumData.CoverImage + ')'}}>
                        </div>
                        <img src={config.baseURL + config.imageURL + this.props.albumData.CoverImage} alt="CoverImage"/>
                        <div className={'album_container--header--text'}>
                            {this.props.albumData.Album_Name}
                        </div>
                    </div>
                    {Object.keys(this.props.albumData.Songs).map(song => {
                        return <SongContainer key={song} songData={this.props.albumData.Songs[song]} CoverImage={this.props.albumData.CoverImage} />
                    })}
                </div>
            </div>
        )
    }

    back = () => {
        this.props.resetAlbumDetail();
    }
}

const mapStateToProps = (state) => ({
    albumDetail: state.music.albumDetail,
    albumData: state.music.songs[state.music.albumDetail.ID],
})

const mapDispatchToProps = {
    resetAlbumDetail
}

export default connect(mapStateToProps,mapDispatchToProps)(AlbumDetail);