import React, {Component} from "react";
import {connect} from "react-redux";
import './main_menu.scss';
import config from "../config";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {loadData} from "../Functions/Functions";
import {response} from "../Modal/ModalReducer";
import PropTypes from "prop-types";
import {setNotificationCount, toggleCheckNotifications} from "../../BAWE/BAWEReducer";

class MainMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notification_count: 0,
        }
    }

    componentDidMount() {
        this.initial_load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.check_notifications !== prevProps.check_notifications) {
            this.initial_load();
            this.props.toggleCheckNotifications(false);
        }
    }

    render() {
        return (
            <div className={'main_menu'}>
                <NavLink to={'/'}>
                    <div>
                        <img src={config.baseURL + config.imageURL + 'logo_icon.png'} alt="Alex Hinterleitner Logo"/>
                    </div>
                </NavLink>
                <div className={'nav'}>
                    <NavLink to={'/'} className="main_menu--elem">
                        <div>
                            <FontAwesomeIcon icon={'home'}/>
                        </div>
                    </NavLink>
                    <NavLink to={'/About'} className="main_menu--elem">
                        <div>
                            <FontAwesomeIcon icon={'info'}/>
                        </div>
                    </NavLink>
                    <NavLink to={'/Music'} className="main_menu--elem">
                        <div>
                            <FontAwesomeIcon icon={'music'}/>
                        </div>
                    </NavLink>
                    <NavLink to={'/User'} className="main_menu--elem">
                        <div><FontAwesomeIcon icon='user'/></div>
                    </NavLink>
                    <NavLink to={'/BAWE'} className="main_menu--elem">
                        <div><FontAwesomeIcon icon='book'/></div>
                    </NavLink>
                    {this.props.userData.access === 1 &&
                        <NavLink to={'/BAWE_Notifications'} className="main_menu--elem">
                            <div className={'main_menu__notification_icon'}>
                                <FontAwesomeIcon icon='bell'/>
                                {this.props.notification_count && this.props.notification_count > 0 ?
                                    <div
                                        className={'main_menu__notification_icon__count'}>{this.props.notification_count}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </NavLink>
                    }
                    {/*{this.props.userData.UserRights === 10 &&*/}
                    {/*    <NavLink to={'/Musicians'} className="main_menu--elem">*/}
                    {/*        <div><FontAwesomeIcon icon='icons' /></div>*/}
                    {/*    </NavLink>*/}
                    {/*}*/}
                </div>
            </div>
        )
    }

    initial_load = () => {
        this.props.loadData({
            Query     : 'BAWE_load_notification_count',
            title     : '',
            errorTitle: '',
            callBack  : this.props.setNotificationCount,
            response  : this.props.response,
            hidden    : true,
        })
    }

    storeNotificationCount = (data) => {
        this.setState({
            notification_count: data.count,
        })
    }
}

MainMenu.propTypes = {
    loadData                : PropTypes.func,
    response                : PropTypes.func,
    userData                : PropTypes.object,
    setNotificationCount    : PropTypes.func,
    notification_count      : PropTypes.number,
    check_notifications     : PropTypes.bool,
    toggleCheckNotifications: PropTypes.func,
}

const mapStateToProps = (state) => ({
    userData           : state.user.userData,
    notification_count : state.BAWE.notification_count,
    check_notifications: state.BAWE.check_notifications,
})

const mapDispatchToProps = {
    loadData,
    response,
    setNotificationCount,
    toggleCheckNotifications,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);