import Translations from "../Base/Translations";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

const UserOverviewAccount = (props) => {
    return (
        <div>
            <div className={'user_overview--data--account_detail'}>
                <div className="user_overview--data--account_detail--elem">
                    <div className="user_overview--data--account_detail--elem--single">
                        E-Mail:
                    </div>
                    <div className="user_overview--data--account_detail--elem--single">
                        {props.userData.UserMail}
                    </div>
                </div>
                <div className="user_overview--data--account_detail--elem">
                    <div className="user_overview--data--account_detail--elem--single">
                        {Translations.firstName[props.userData.Language]}:
                    </div>
                    <div className="user_overview--data--account_detail--elem--single">
                        {props.userData.PreName}
                    </div>
                </div>
                <div className="user_overview--data--account_detail--elem">
                    <div className="user_overview--data--account_detail--elem--single">
                        {Translations.lastName[props.userData.Language]}:
                    </div>
                    <div className="user_overview--data--account_detail--elem--single">
                        {props.userData.LastName}
                    </div>
                </div>
            </div>
            <div className="user_overview--data__button user_overview--data__button--red user_overview--data__button--right"
                 onClick={props.deleteAccount}>
                <FontAwesomeIcon icon={'trash'}/>
                <div>
                    {Translations.deleteAccount[props.userData.Language]}
                </div>
            </div>
        </div>
    )
}

export default UserOverviewAccount;