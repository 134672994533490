import Input from "../Base/Components/Input";
import Translations from "../Base/Translations";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const UserLogin = (props) => {
    const [togglePWvisibility, setTogglePWvisibility] = useState(false);

    return (
        <div className={'user_container--form'}>
            <Input
                className={props.userLogin.UserMail !== '' && props.userLogin.UserMail.includes('@')
                    ? 'user_container--form--input user_container--form--input--good'
                    :
                    'user_container--form--input user_container--form--input--empty'}
                type={'email'}
                name={'UserMail'}
                label={'E-Mail'}
                placeholder={'E-Mail'}
                onChange={props.onChange}
                value={props.userLogin.UserMail}
            />
            <div className="password_input">
                <Input
                    className={props.userLogin.Password !== ''
                        ? 'user_container--form--input user_container--form--input--good'
                        :
                        'user_container--form--input user_container--form--input--empty'}
                    type={togglePWvisibility ? 'text' : 'password'}
                    name={'Password'}
                    label={Translations.password[props.userData.Language]}
                    placeholder={Translations.password[props.userData.Language]}
                    onChange={props.onChange}
                    value={props.userLogin.Password}
                />
                <div className="password_input__icon" onClick={() => setTogglePWvisibility(!togglePWvisibility)}>
                    <FontAwesomeIcon icon={togglePWvisibility ? 'eye-slash' : 'eye'} />
                </div>
            </div>
            <div className={props.userLogin.Username !== ''
            && props.userLogin.Password !== '' ?
                "login_button login_button--good"
                :
                "login_button login_button--empty"} onClick={props.login}>Login
            </div>
        </div>
    )
}

export default UserLogin;