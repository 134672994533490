import React, {Component} from "react";
import {connect} from "react-redux";
import './about.scss';
import config from "../Base/config";
import {SocialIcon} from "react-social-icons";
import Footer from "../Base/Footer/Footer";

class About extends Component {
    constructor(props) {
        super(props);

        document.title = 'Alex Hinterleitner | About';
    }

    render() {
        return (
            <div className={'about_main'}
                 style={{ backgroundImage: 'url(' + config.baseURL + config.imageURL + 'Main2.jpg)' }}>
                <div className="info">
                    <h2>About</h2>
                    {this.props.userData.Language === 'DE' ?
                        <p className="info__text">
                            Mein Name ist Alex Hinterleitner und ich bin {config.age()} Jahre alt.<br/>
                            Ich bin ein Musiker und Singer/Songwriter aus Salzburg, Österreich.
                            <br/><br/>
                            Ich spiele Gitarre, Klavier, E-Bass und Mundharmonika und habe vor kurzem angefangen, Saxophon zu lernen.
                            <br/><br/>
                            Mit 17 schrieb ich meinen ersten Song „Alone“.<br/>
                            Im Laufe der Jahre wurde meine Liebe zur Musik zu einem unverzichtbaren Bestandteil meines Lebens.<br/>
                            Also beschloss ich, meine Leidenschaft mit der Welt zu teilen.
                            <br/><br/>
                            2020 habe ich meine erste EP „step by step“ veröffentlicht.<br/>
                            2021 habe ich meine neue EP „Soulmate“ fertiggestellt, die noch dieses Jahr auf allen Plattformen erscheinen wird.<br/>
                            Die Songs von „Soulmate“ können bereits jetzt exklusiv auf dieser Seite anhört werden.<br/>
                            Außerdem kommt dieses Jahr auch noch eine Piano-EP.
                            <br/><br/>
                            Außerdem bin ich noch Teil zwei weiterer Bands:
                            <br/><br/>
                            ► <a href="https://somersbymusic.com/" target={'_blank'} rel={'noreferrer'}>Somersby</a><br/><br/>
                            ► <a href="https://twice-aced.com/" target={'_blank'} rel={'noreferrer'}>Twice Aced</a>
                        </p>
                    :
                        <p>
                            My name is Alex Hinterleitner and I'm {config.age()} years old.<br/>
                            I'm a musician, singer and songwriter located in Salzburg, Austria
                            <br/><br/>
                            I'm playing Guitar, Piano, E-Bass and Harmonica and most recently started learning to play the saxophone .
                            <br/><br/>
                            With the age of 17 I wrote my first song "Alone".<br/>
                            Over the years my love for creating music became an indispensable part in my life.<br/>
                            So I decided to share my passion with the world.
                            <br/><br/>
                            In 2020 I released my first EP "step by step".<br/>
                            In 2021 I finished my new EP "Soulmate", which will be released this year on all platforms.<br/>
                            You can already listen to the Songs of "Soulmate" exclusively on this page.<br/>
                            Also there's a Piano-EP coming up this year.<br/>
                            <br/><br/>
                            I'm also part of two other bands, check them out:
                            <br/><br/>
                            ► <a href="https://somersbymusic.com/" target={'_blank'} rel={'noreferrer'}>Somersby</a><br/><br/>
                            ► <a href="https://twice-aced.com/" target={'_blank'} rel={'noreferrer'}>Twice Aced</a>
                        </p>
                    }
                </div>
                <div className="social_media">
                    <SocialIcon url={'https://www.instagram.com/alexh.music/'}
                                target={'_blank'}
                                className={'social_media--icon'}
                                fgColor={'white'} bgColor={'black'} style={{ width: 60, height: 60 }}/>
                    <SocialIcon url={'https://www.facebook.com/HinterleitnerAlex/'}
                                target={'_blank'}
                                className={'social_media--icon'}
                                fgColor={'white'} bgColor={'black'} style={{ width: 60, height: 60 }}/>
                    <SocialIcon url={'https://www.youtube.com/channel/UCP05aFbNnYHntiMLE_Jb_fg'}
                                target={'_blank'}
                                className={'social_media--icon'}
                                fgColor={'white'} bgColor={'black'} style={{ width: 60, height: 60 }}/>
                    <SocialIcon url={'https://www.tiktok.com/@alexhinterleitner'}
                                target={'_blank'}
                                className={'social_media--icon'}
                                fgColor={'white'} bgColor={'black'} style={{ width: 60, height: 60 }}/>
                    <SocialIcon url={'https://open.spotify.com/artist/1j8moJh8AWjqC4NZS8mJRt?si=5pts90UESea-dbeE1-ZLNQ'}
                                target={'_blank'}
                                className={'social_media--icon'}
                                fgColor={'white'} bgColor={'black'} style={{ width: 60, height: 60 }}/>
                    <SocialIcon url={'https://soundcloud.com/alex_h1996'}
                                target={'_blank'}
                                className={'social_media--icon'}
                                fgColor={'white'} bgColor={'black'} style={{ width: 60, height: 60 }}/>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.userData,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(About);