import React, {Component} from "react";
import {connect} from "react-redux";
import Translations from "../Translations";

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="policy_div">
                <div className={'policy'}>
                    <h2>{Translations.privacy_policy[this.props.userData.Language]}</h2>
                    {this.props.userData.Language === 'DE' ?
                        <div className={'mt--20'}>
                            <b>Name der Datenverarbeitung: Benutzerkonto</b>
                            <br/><br/>
                            <b>Rechtsgrundlage Einwilligung</b>
                            <br/><br/>
                            Sie haben uns Daten über sich freiwillig zur Verfügung gestellt und wir verarbeiten diese Daten auf Grundlage Ihrer Einwilligung zu folgenden Zwecken:
                            <br/><br/>
                            Speicherung benutzbezogener Likes eines Songs und Speicherung der Spracheinstellungen.
                            <br/><br/>
                            Sie können diese Einwilligung jederzeit widerrufen. Ein Widerruf hat zur Folge, dass wir Ihre Daten ab diesem Zeitpunkt zu oben genannten Zwecken nicht mehr verarbeiten. Für einen Widerruf wenden Sie sich bitte an:  <br/>
                            Alexander Hinterleitner<br/>
                            +43 660 46 55 845<br/>
                            contact@alexhinterleitner.com
                            <br/><br/>
                            <b>Speicherdauer/Löschungsfrist</b>
                            <br/><br/>
                            Wir speichern Ihre Daten bis auf Widerruf. Sie können alle Daten die zu Ihrem Benutzer gespeichert wurden jederzeit selbst löschen. Dazu können Sie in Ihrer persönlichen Nutzerübersicht auf "Benutzer löschen" klicken. Dadurch werden alle Daten gelöscht, die zu Ihrem Benutzer und in Verbindung mit Ihrem Benutzer gespeichert wurden (z.B. auch Likes).
                            <br/><br/>
                            Ihre Daten werden nicht an Dritte weitergegeben.
                            <br/><br/>
                            <b>Kontakt</b>
                            <br/><br/>
                            Sie erreichen uns unter folgenden Kontaktdaten: <br/>
                            Alexander Hinterleitner<br/>
                            +43 660 46 55 845<br/>
                            contact@alexhinterleitner.com
                            <br/><br/>
                            <b>Rechtsbehelfsbelehrung</b>
                            <br/><br/>
                            Da wir die Daten (auch) für Direktwerbung verarbeiten, können Sie gegen diese Verarbeitung für Zwecke der Direktwerbung jederzeit Widerspruch erheben
                            <br/><br/>
                            Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit und Widerspruch zu. Dafür wenden Sie sich an uns.
                            <br/><br/>
                            Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist die Datenschutzbehörde zuständig.
                        </div>
                    :
                        <div>
                            <b>Data processing name: User account</b>
                            <br/><br/>
                            <b>Rechtsgrundlage Einwilligung - Legal basis Consent</b>
                            <br/><br/>
                            You have voluntarily provided us with personal data which we process with your consent for the following purposes:
                            <br/><br/>
                            Storage of user-related likes of a song and storage of language settings.
                            <br/><br/>
                            You can withdraw this consent at any time. Withdrawal will result in your data no longer being processed for the above purposes from that time on. To withdraw your consent, please contact:  <br/>
                            Alexander Hinterleitner<br/>
                            +43 660 46 55 845<br/>
                            contact@alexhinterleitner.com
                            <br/><br/>
                            <b>Speicherdauer/Löschungsfrist - Storage period/deletion period</b>
                            <br/><br/>
                            We store your data until further notice. You can delete all data stored about your user at any time. To do this, you can click on "Delete account" in your personal user overview. This will delete all data that was stored about your user and in connection with your user (e.g. likes).
                            <br/><br/>
                            Your data will not be disclosed to third parties.
                            <br/><br/>
                            <b>Contact</b>
                            <br/><br/>
                            You can reach us at: <br/>
                            Alexander Hinterleitner<br/>
                            +43 660 46 55 845<br/>
                            contact@alexhinterleitner.com
                            <br/><br/>
                            <b>Rechtsbehelfsbelehrung - Legal appeal</b>
                            <br/><br/>
                            Because we (also) process the data for direct advertising, you can at any time object to this processing for purposes of direct advertising.
                            <br/><br/>
                            Basically, you have the right to access, rectification, erasure, restriction, data portability and to object. Contact us about this.
                            <br/><br/>
                            If you believe that the processing of your data contravenes data-protection law or your legal claim to protection of your data has been violated in some other way, you can complain to the supervisory authorities. The Data Protection Authority is responsible for this in Austria.
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.userData,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps,mapDispatchToProps)(PrivacyPolicy);