const Translations = {
    register                : {
        DE: 'Registrieren',
        EN: 'Register',
    },
    password                : {
        DE: 'Passwort',
        EN: 'Password',
    },
    firstName               : {
        DE: 'Vorname',
        EN: 'First name',
    },
    lastName                : {
        DE: 'Nachname',
        EN: 'Last name',
    },
    errorOccured            : {
        DE: 'Es ist ein Fehler aufgetreten',
        EN: 'An Error occured',
    },
    UserNotFound            : {
        DE: 'Kein Benutzer gefunden',
        EN: 'User not found',
    },
    WrongMailOrPassword     : {
        DE: 'Falsche E-Mail oder Passwort',
        EN: 'Wrong E-Mail or Password',
    },
    WrongPassword     : {
        DE: 'Falsches Passwort',
        EN: 'Wrong Password',
    },
    UserExists              : {
        DE: 'Dieser Benutzername ist bereits vergeben',
        EN: 'Username is already taken',
    },
    userLoading             : {
        DE: 'Benutzer wird geladen',
        EN: 'Loading user',
    },
    userLoadingError        : {
        DE: 'Benutzer konnte nicht geladen werden',
        EN: 'User could not be loaded',
    },
    songsLoading            : {
        DE: 'Songs werden geladen',
        EN: 'Loading songs',
    },
    songsLoadingError       : {
        DE: 'Songs konnten nicht geladen werden',
        EN: 'Songs could not be loaded',
    },
    songLoading             : {
        DE: 'Song wird geladen',
        EN: 'Loading song',
    },
    songLoadingError        : {
        DE: 'Song konnte nicht geladen',
        EN: 'Song could not be loaded',
    },
    myAccount               : {
        DE: 'Mein Konto',
        EN: 'My account',
    },
    language                : {
        DE: 'Sprache',
        EN: 'Language',
    },
    languageChange          : {
        DE: 'Sprache wird geändert',
        EN: 'Language is being changed'
    },
    languageChangeError     : {
        DE: 'Sprache konnte nicht geändert werden',
        EN: 'Language could not be changed',
    },
    baseInfoText            : {
        DE: 'Alex Hinterleitner ist ein Singer/Songwriter aus Salzburg, Österreich.',
        EN: 'Alex Hinterleitner is a Singer/Songwriter based in Salzburg, Austria.',
    },
    chooseLanguage          : {
        DE: 'Sprache auswählen',
        EN: 'Choose language',
    },
    noUserSongLike          : {
        DE: 'Um Songs zu liken, musst du eingeloggt sein.',
        EN: 'You have to be logged in to like songs.',
    },
    likedBy                 : {
        DE: 'Geliked von',
        EN: 'Liked by',
    },
    deleteAccount           : {
        DE: 'Benutzer löschen',
        EN: 'Delete account',
    },
    yes                     : {
        DE: 'Ja',
        EN: 'Yes',
    },
    no                      : {
        DE: 'Nein',
        EN: 'No',
    },
    questionDeleteAccount   : {
        DE: 'Möchten Sie ihren Benutzer wirklich löschen?',
        EN: 'Do you really want to delete your account?',
    },
    deletingAccount         : {
        DE: 'Benutzer wird gelöscht',
        EN: 'Deleting account',
    },
    failedDeletingAccount   : {
        DE: 'Benutzer konnte nicht gelöscht werden',
        EN: 'Deleting account failed'
    },
    impressum               : {
        DE: 'Impressum',
        EN: 'Imprint',
    },
    privacy_policy          : {
        DE: 'Datenschutzbestimmungen',
        EN: 'Privacy Policy',
    },
    Projekte                : {
        DE: 'Zu den Projekten',
        EN: 'Show Projects',
    },
    chooseBundesland        : {
        DE: 'Bundesland wählen',
        EN: 'Choose state',
    },
    activateNotificationText: {
        DE: 'Ich möchte über neue Einträge in die BAWE-Liste per E-Mail informiert werden',
        EN: 'I would like to be informed about new entries in the BAWE list by email',
    },
    data                    : {
        DE: 'Daten',
        EN: 'Data'
    },
    addNewWord              : {
        DE: 'Neues Wort hinzufügen',
        EN: 'Add new word',
    },
    badWord                 : {
        DE: 'Wort ist unanständig',
        EN: 'Word is indecent',
    },
    newDescription          : {
        DE: 'Neue Beschreibung',
        EN: 'New description',
    },
    searchFor               : {
        DE: 'Suche nach',
        EN: 'Search for',
    },
    yourWordOfTheDay        : {
        DE: 'Dein Wort des Tages',
        EN: 'Your word of the day',
    },
    duplicate               : {
        DE: 'Duplikat',
        EN: 'Duplicate',
    },
    entryAlreadyExists      : {
        DE: 'Dieser Eintrag existiert bereits',
        EN: 'This entry already exists',
    },
    noNotifications         : {
        DE: 'Du hast derzeit keine Benachrichtigungen',
        EN: 'You currently have no notifications',
    },
    writeAComment           : {
        DE: 'Schreibe ein Kommentar',
        EN: 'Write a comment',
    },
    changePassword          : {
        DE: 'Passwort ändern',
        EN: 'Change password',
    },
    liked_by: {
        DE: 'gefällt',
        EN: 'liked by',
    },
    andMoreUsers: {
        DE: 'weitere',
        EN: 'more',
    }
}

export default Translations;