import React, {Component} from "react";
import {connect} from "react-redux";
import './home.scss';
import config from "../Base/config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {saveData} from "../Base/Functions/Functions";
import {response} from "../Base/Modal/ModalReducer";
import Translations from "../Base/Translations";

class HomeContainer extends Component {
    constructor(props) {
        super(props);

        this.projRef = React.createRef();
        this.homeRef = React.createRef();

        document.title = 'Alex Hinterleitner | Home';
    }

    render() {
        return (
            <div ref={this.homeRef} className="home">
                <div className="home--welcome" style={{backgroundImage: 'url(' + config.baseURL + config.imageURL + 'Main.jpg)'}}>
                    <div className="home--info">
                        <div>
                            <h1>Alex Hinterleitner</h1>
                            <h4>Singer/Songwriter</h4>
                            <h5>{config.age()}</h5>
                            <p>{Translations.baseInfoText[this.props.userData.Language]}</p>
                            <div className="action_button action_button--to" onClick={this.scrollToProjects}>{Translations.Projekte[this.props.userData.Language]}</div>
                        </div>
                        <div></div>
                    </div>
                </div>
                <div ref={this.projRef} className="home--latest">
                    <div className="home--latest--youtube">
                        <iframe src="https://www.youtube.com/embed/PAQOdKiKXlM"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/RJB-dQwZpME"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/B-wqWGqCpls"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                    </div>
                </div>
                <div className={this.props.scrollTop > 300 ? this.props.musicPlayer.open ? "scrollToTop scrollToTop--show scrollToTop--player_active" : 'scrollToTop scrollToTop--show' : 'scrollToTop'} onClick={this.scrollToTop}>
                    <FontAwesomeIcon icon={'chevron-circle-up'} />
                </div>
            </div>
        )
    }

    scrollToProjects = () => {
        this.projRef.current.scrollIntoView({behavior: 'smooth'});
    }

    scrollToTop = () => {
        this.homeRef.current.scrollIntoView({behavior: 'smooth'});
    }
}

const mapStateToProps = (state) => ({
    musicPlayer: state.musicPlayer,
    userData: state.user.userData,
})

const mapDispatchToProps = {
    saveData,
    response,
}

export default connect(mapStateToProps,mapDispatchToProps)(HomeContainer);