import React, {Component} from "react";
import {connect} from "react-redux";
import './alert.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class AlertContainer extends Component {
    render() {
        return (
            <div className={this.props.alert.open ? "alert_container alert_container--open" : "alert_container"}>
                <div className="alert_container--header">
                    <FontAwesomeIcon icon={'warning'} />
                    {this.props.alert.header}
                </div>
                <div className="alert_container--text">
                    {this.props.alert.text}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    alert: state.alert,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps,mapDispatchToProps)(AlertContainer);