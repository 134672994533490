import Input from "../Base/Components/Input";
import Translations from "../Base/Translations";
import Select from "../Base/Components/Select";
import InputCheckbox from "../Base/Components/InputCheckbox";
import {NavLink} from "react-router-dom";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState} from "react";

const UserRegister = (props) => {
    const [togglePWvisibility, setTogglePWvisibility] = useState(false);

    return (
        <div className={'user_container--form'}>
            <Input
                className={props.userLogin.UserMail !== '' && props.userLogin.UserMail.includes('@')
                    ? 'user_container--form--input user_container--form--input--good'
                    :
                    'user_container--form--input user_container--form--input--empty'}
                type={'email'}
                name={'UserMail'}
                label={'E-Mail*'}
                placeholder={'E-Mail'}
                onChange={props.onChange}
                value={props.userLogin.UserMail}
            />
            <Input
                className={props.userLogin.PreName !== ''
                    ? 'user_container--form--input user_container--form--input--good'
                    :
                    'user_container--form--input user_container--form--input--empty'}
                type={'text'}
                name={'PreName'}
                label={Translations.firstName[props.userData.Language] + '*'}
                placeholder={Translations.firstName[props.userData.Language]}
                onChange={props.onChange}
                value={props.userLogin.PreName}
            />
            <Input
                className={props.userLogin.LastName !== ''
                    ? 'user_container--form--input user_container--form--input--good'
                    :
                    'user_container--form--input user_container--form--input--empty'}
                type={'text'}
                name={'LastName'}
                label={Translations.lastName[props.userData.Language] + '*'}
                placeholder={Translations.lastName[props.userData.Language]}
                onChange={props.onChange}
                value={props.userLogin.LastName}
            />
            <div className="password_input">
                <Input
                    className={props.userLogin.Password !== ''
                        ? 'user_container--form--input user_container--form--input--good'
                        :
                        'user_container--form--input user_container--form--input--empty'}
                    type={togglePWvisibility ? 'text' : 'password'}
                    name={'Password'}
                    label={Translations.password[props.userData.Language] + '*'}
                    placeholder={Translations.password[props.userData.Language]}
                    onChange={props.onChange}
                    value={props.userLogin.Password}
                />
                <div className="password_input__icon" onClick={() => setTogglePWvisibility(!togglePWvisibility)}>
                    <FontAwesomeIcon icon={togglePWvisibility ? 'eye-slash' : 'eye'} />
                </div>
            </div>
            <fieldset className={'user_container--form__BAWE'}>
                <legend>BAWE {Translations.data[props.userData.Language]} (optional)</legend>
                <Select
                    className={'user_container--form__select'}
                    name={'Bundesland'}
                    label={Translations.chooseBundesland[props.userData.Language]}
                    options={props.Bundeslaender}
                    value={props.userLogin.Bundesland}
                    onChange={props.onChange}
                    optionLabel={'Name'}
                />
                {/*<InputCheckbox*/}
                {/*    label={Translations.activateNotificationText[props.userData.Language]}*/}
                {/*    name={'BAWE_notification'}*/}
                {/*    checked={props.BAWE_Notification}*/}
                {/*    onChange={props.onChange}*/}
                {/*/>*/}
            </fieldset>

            <InputCheckbox
                name={'privacy_policy'}
                label={props.userData.Language === 'DE' ?
                    <div
                        className={props.userLogin.privacy_policy
                            ? 'user_container--form--input user_container--form--input--privacy_good'
                            :
                            'user_container--form--input user_container--form--input--privacy_empty'}>Ich
                        stimme den <NavLink
                            to={'/PrivacyPolicy'}>Datenschutzbestimmungen</NavLink> zu</div>
                    :
                    <div
                        className={props.userLogin.privacy_policy
                            ? 'user_container--form--input user_container--form--input--privacy_good'
                            :
                            'user_container--form--input user_container--form--input--privacy_empty'}>I
                        agree to the <NavLink to={'/PrivacyPolicy'}>privacy policy</NavLink></div>}
                checked={props.userLogin.privacy_policy}
                onChange={props.onChange}
            />
            <div className={props.userLogin.Username !== ''
            && props.userLogin.UserMail !== ''
            && props.userLogin.PreName !== ''
            && props.userLogin.LastName !== ''
            && props.userLogin.Password !== ''
            && props.userLogin.privacy_policy ?
                "login_button login_button--good"
                :
                "login_button login_button--empty"}
                 onClick={props.register}>{Translations.register[props.userData.Language]}</div>
        </div>
    )
}

export default UserRegister;