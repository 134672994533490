import React, {Component} from "react";
import {connect} from "react-redux";
import './musicplayerstyle.scss';
import config from "../../Base/config";
import {unsetReplay, togglePlayPause, togglePlayer, toggleFullView, setSong} from "./MusicPlayerReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {saveData} from "../../Base/Functions/Functions";
import {response} from "../../Base/Modal/ModalReducer";
import {completeSaveLike, setAlbumDetail} from "../MusicReducer";
import {toggleAlert, closeAlert} from "../../Base/Alert/AlertReducer";

class MusicPlayerContainer extends Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();

        this.state = {
            sliderValue: 0,
            play       : this.props.musicPlayer.play,
            volume     : this.myRef.current ? this.myRef.current.volume : 100,
            allowPause : false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.musicPlayer.Album_ID !== this.props.musicPlayer.Album_ID
            || prevProps.musicPlayer.Song_ID !== this.props.musicPlayer.Song_ID) {
            if(!this.state.play && this.props.musicPlayer.play) {
                setTimeout(() => {
                    this.playSong();
                    this.props.togglePlayPause(true);
                }, 200);
            }else{
                setTimeout(() => {
                    this.pauseSong();
                    this.props.togglePlayPause(false);
                }, 300);
            }
        }
        if (this.props.musicPlayer.Album_ID) {
            if (this.props.musicPlayer.replay) {
                this.myRef.current.currentTime = 0;
                this.props.unsetReplay();
                this.props.togglePlayPause(true);
            }
            if (this.props.musicPlayer.play) {
                this.playSong();
            } else {
                this.pauseSong();
            }
        }
        if (this.props.sliderTime !== prevProps.sliderTime) {
            this.myRef.current.currentTime = this.props.sliderTime;
        }
        if (this.props.nextSong !== prevProps.nextSong) {
            this.changeSong('next');
            this.props.resetNextSong();
        }
        if (this.props.prevSong !== prevProps.prevSong) {
            this.changeSong('prev');
            this.props.resetPrevSong();
        }
    }

    playSong = () => {
        this.myRef.current.play().then(() => {
            setTimeout(() => {
                this.setState({
                    allowPause: true,
                })
            },1000);
        })
    }

    pauseSong = () => {
        if (!this.myRef.current.paused) {
            this.myRef.current.pause();
        }
    }

    audioPlay = () => {
        this.props.togglePlayPause(true);
    }

    audioPause = (e) => {
        this.props.togglePlayPause(false);
    }

    audioDurationChange = () => {
        this.setState({
            sliderValue: this.myRef.current.currentTime,
        });
        this.props.setAudioTime(this.myRef.current.currentTime);
    }

    render() {
        let album_id;
        let song_id;
        let song_title;
        let album_title;
        let song_url;
        let cover_image_url;
        if (this.props.musicPlayer.Album_ID) {
            album_id = this.props.musicPlayer.Album_ID;
            song_id = this.props.musicPlayer.Song_ID;
            song_title = this.props.songs[album_id].Songs[song_id].Song_Title;
            album_title = this.props.songs[album_id].Album_Name;
            song_url = config.baseURL + config.audioURL + this.props.songs[album_id].Songs[song_id].AudioFileName;
            cover_image_url = config.baseURL + config.imageURL + this.props.songs[album_id].Songs[song_id].CoverImage;
        }

        return (
            <div className={this.props.musicPlayer.open ? 'music_player music_player--opened' : 'music_player'}>
                {this.props.musicPlayer.Album_ID &&
                    <FontAwesomeIcon
                        className={this.props.musicPlayer.open ? 'closePlayerIcon' : 'closePlayerIcon closePlayerIcon--closed'}
                        icon={'chevron-down'} onClick={this.togglePlayer}/>
                }
                <div className={'song_info_container'} onClick={this.toggleFullView}>
                    {this.props.musicPlayer.Album_ID &&
                        <img src={cover_image_url} alt="coverImage"/>
                    }
                    <div className={'song_info_container--text'}>
                        <div className={'song_info_container--title'}>{song_title}</div>
                        <div className={'song_info_container--album'}>{album_title}</div>
                    </div>
                </div>
                <div className={'audioControls'}>
                    <div className={'playerIcons'}>
                        <FontAwesomeIcon className={'playerIcon'} icon={this.props.musicPlayer.play ? 'pause' : 'play'}
                                         onClick={this.togglePlayPause}/>
                    </div>
                    <audio ref={this.myRef} src={song_url} onPlay={this.audioPlay} onPause={this.audioPause}
                           onTimeUpdate={this.audioDurationChange} onEnded={() => this.changeSong('onended')}/>
                </div>
            </div>
        )
    }

    changeSong = (type) => {
        if (this.myRef.current.currentTime !== 0 || type === 'onended') {
            if (type === 'onended') type = 'next';
            if (type === 'prev' && this.myRef.current.currentTime > 2) {
                this.myRef.current.currentTime = 0;
                this.setState({
                    sliderValue: 0,
                })
            } else {
                let album_id = this.props.musicPlayer.Album_ID;
                let song_id = this.props.musicPlayer.Song_ID;

                let songs = this.props.songs[album_id].Songs;
                let songs_id_arr = Object.keys(songs);
                let curr_index = songs_id_arr.indexOf(song_id.toString());
                let next_song;
                if (type === 'next') {
                    if (this.props.musicPlayer.repeat === 1) {
                        next_song = songs[songs_id_arr[curr_index]];
                    } else {
                        next_song = songs[songs_id_arr[curr_index + 1]];
                    }
                } else if (type === 'prev') {
                    next_song = songs[songs_id_arr[curr_index - 1]];
                }
                let next_song_id;

                let alben = this.props.songs;
                let album_id_arr = Object.keys(alben);
                let curr_album_index = album_id_arr.indexOf(album_id.toString());
                let next_album;
                if (type === 'next') {
                    if (this.props.musicPlayer.repeat === 2) {
                        next_album = alben[album_id_arr[curr_album_index]];
                    } else {
                        next_album = alben[album_id_arr[curr_album_index + 1]];
                    }
                } else if (type === 'prev') {
                    next_album = alben[album_id_arr[curr_album_index - 1]];
                }
                let next_album_id;
                if (next_album) {
                    next_album_id = next_album.Album_ID;
                }

                let next_album_first_song_id;

                if (next_song) {
                    next_song_id = next_song.Song_ID;
                } else {
                    if (next_album) {
                        let next_songs = this.props.songs[next_album_id].Songs;
                        let next_album_songs = this.props.songs[next_album_id].Songs;
                        let next_album_songs_id_arr = Object.keys(next_album_songs);
                        let next_album_first_song;
                        if (type === 'next') {
                            next_album_first_song = next_songs[next_album_songs_id_arr[0]];
                        } else if (type === 'prev') {
                            next_album_first_song = next_songs[next_album_songs_id_arr[next_album_songs_id_arr.length - 1]];
                        }
                        next_album_first_song_id = next_album_first_song.Song_ID;
                        album_id = next_album_id;
                        next_song_id = next_album_first_song_id;
                    }
                }
                if (next_album || next_song) {
                    this.props.setSong({
                        Album_ID: album_id,
                        Song_ID : next_song_id,
                    });
                    this.props.setAlbumDetail(album_id);
                } else {
                    this.props.setSong({
                        Album_ID: alben[album_id_arr[0]].Album_ID,
                        Song_ID : this.props.songs[alben[album_id_arr[0]].Album_ID].Songs[Object.keys(this.props.songs[alben[album_id_arr[0]].Album_ID].Songs)[0]].Song_ID,
                    });
                    this.props.setAlbumDetail(alben[album_id_arr[0]].Album_ID);
                }
            }
        }
    }

    toggleFullView = () => {
        this.props.toggleFullView();
    }

    togglePlayer = () => {
        this.props.togglePlayer();
    }

    togglePlayPause = () => {
        this.props.togglePlayPause(!this.props.musicPlayer.play);
    }
}

const mapStateToProps = (state) => ({
    songs      : state.music.songs,
    musicPlayer: state.musicPlayer,
    userData   : state.user.userData,
})

const mapDispatchToProps = {
    saveData,
    response,
    unsetReplay,
    togglePlayPause,
    togglePlayer,
    completeSaveLike,
    toggleAlert,
    closeAlert,
    toggleFullView,
    setSong,
    setAlbumDetail,
}

export default connect(mapStateToProps, mapDispatchToProps)(MusicPlayerContainer);