import React, {Component} from "react";
import {connect} from "react-redux";
import {editUserLogin, storeUser, userLogout, setUserLanguage} from "../Base/UserReducer";
import './user.scss';
import {saveData, loadData} from "../Base/Functions/Functions";
import {response} from "../Base/Modal/ModalReducer";
import Translations from "../Base/Translations";
import config from "../Base/config";
import {openEditModal} from "../Base/Modal/ModalReducer";
import PropTypes from "prop-types";
import UserLogin from "./UserLogin";
import UserRegister from "./UserRegister";
import UserOverview from "./UserOverview";

class UserContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type  : 'Login',
            detail: 'lang',
        }

        document.title = 'Alex Hinterleitner | User';
    }

    render() {
        return (
            <div className={'user_container'}
                 style={{backgroundImage: 'url(' + config.baseURL + config.imageURL + 'Tirol4.jpg)'}}>
                {this.props.userData.access === 0 ?
                    <div className="user_login">
                        <div className="user_container--header">
                            <div
                                className={this.state.type === 'Login' ? "user_container--header--elem user_container--header--elem--active" : "user_container--header--elem"}
                                onClick={() => this.toggleType('Login')}>
                                Login
                            </div>
                            <div
                                className={this.state.type !== 'Login' ? "user_container--header--elem user_container--header--elem--active" : "user_container--header--elem"}
                                onClick={() => this.toggleType(Translations.register[this.props.userData.Language])}>
                                {Translations.register[this.props.userData.Language]}
                            </div>
                        </div>
                        <h2>{this.state.type}</h2>
                        {this.state.type === 'Login' ?
                            <UserLogin userLogin={this.props.userLogin} onChange={this.onChange} login={this.login} userData={this.props.userData} />
                            :
                            <UserRegister userLogin={this.props.userLogin} Bundeslaender={this.props.Bundeslaender} onChange={this.onChange} register={this.register} userData={this.props.userData} />
                        }
                    </div>
                    :
                    <UserOverview setDetail={this.setDetail} userData={this.props.userData} detail={this.state.detail} deleteAccount={this.deleteAccount} changeLanguage={this.changeLanguage} logout={this.logout} />
                }
            </div>
        )
    }

    deleteAccount = () => {
        this.props.openEditModal({
            editType    : 'deleteAccount',
            title       : Translations.deleteAccount[this.props.userData.Language],
            text        : Translations.questionDeleteAccount[this.props.userData.Language],
            confirmColor: 'red',
            cancelColor : 'grey',
        })
    }

    changeLanguage = (lang) => {
        if (lang !== this.props.userData.Language) {
            let fd = new FormData();
            let send_data = {
                User_ID    : this.props.userData.userID,
                newLanguage: lang,
            }
            fd.append('data', JSON.stringify(send_data));
            this.props.saveData({
                Query     : 'setUserLanguage',
                formData  : fd,
                title     : Translations.languageChange[this.props.userData.Language],
                errorTitle: Translations.languageChangeError[this.props.userData.Language],
                callBack  : this.completeLanguageChange,
                response  : this.props.response,
            })
        }
    }

    completeLanguageChange = (data) => {
        this.props.setUserLanguage(data.newLanguage);
    }

    setDetail = (type) => {
        this.setState({
            detail: type,
        })
    }

    logout = () => {
        this.props.loadData({
            Query     : 'logout',
            title     : 'Logout',
            errorTitle: Translations.errorOccured[this.props.userData.Language],
            callBack  : this.completeLogout,
            response  : this.props.response,
        })
    }

    completeLogout = () => {
        this.props.userLogout();
    }

    toggleType = (type) => {
        this.setState({
            type: type,
        })
    }

    onChange = (key, value) => {
        if(key === 'BAWE_notification') {
            value = Number(value);
        }
        this.props.editUserLogin({
            key  : key,
            value: value,
        })
    }

    register = () => {
        if (this.props.userLogin.UserMail !== ''
            && this.props.userLogin.UserMail.includes('@')
            && this.props.userLogin.PreName !== ''
            && this.props.userLogin.LastName !== ''
            && this.props.userLogin.Password !== ''
            && this.props.userLogin.privacy_policy) {
            let fd = new FormData();
            let send_data = this.props.userLogin;
            fd.append('data', JSON.stringify(send_data));
            this.props.saveData({
                Query     : 'userRegister',
                formData  : fd,
                title     : 'User wird registriert',
                errorTitle: 'User konnte nicht registriert werden',
                callBack  : this.completeRegister,
                response  : this.props.response,
            });
        }
    }

    completeRegister = () => {
        this.setState({
            type: 'Login',
        })
    }

    login = () => {
        if (this.props.userLogin.UserMail !== ''
            && this.props.userLogin.UserMail.includes('@')
            && this.props.userLogin.Password !== '') {
            let fd = new FormData();
            let send_data = {
                UserMail: this.props.userLogin.UserMail,
                Password: this.props.userLogin.Password,
            }
            fd.append('data', JSON.stringify(send_data));
            this.props.saveData({
                Query     : 'userLogin',
                formData  : fd,
                title     : 'Login',
                errorTitle: Translations.errorOccured[this.props.userData.Language],
                callBack  : this.completeLogin,
                response  : this.props.response,
                language  : this.props.userData.Language,
            });
        }
    }

    completeLogin = (data) => {
        this.props.storeUser(data.userData);
        window.location.href = config.serverBaseURL + '/Music';
    }
}

UserContainer.propTypes = {
    userData       : PropTypes.object,
    userLogin      : PropTypes.object,
    Bundeslaender  : PropTypes.object,
    saveData       : PropTypes.func,
    loadData       : PropTypes.func,
    response       : PropTypes.func,
    editUserLogin  : PropTypes.func,
    storeUser      : PropTypes.func,
    userLogout     : PropTypes.func,
    setUserLanguage: PropTypes.func,
    openEditModal  : PropTypes.func,
}

const mapStateToProps = (state) => ({
    userData     : state.user.userData,
    userLogin    : state.user.userLogin,
    Bundeslaender: state.user.Bundeslaender,
})

const mapDispatchToProps = {
    saveData,
    loadData,
    response,
    editUserLogin,
    storeUser,
    userLogout,
    setUserLanguage,
    openEditModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);