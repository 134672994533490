import React, {Component} from "react";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './../musicplayerstyle.scss';
import {toggleFullView, togglePlayPause, setRepeat} from "../MusicPlayerReducer";
import config from "../../../Base/config";
import Translations from "../../../Base/Translations";
import {saveData} from "../../../Base/Functions/Functions";
import {response} from "../../../Base/Modal/ModalReducer";
import {completeSaveLike} from "../../MusicReducer";
import {toggleAlert, closeAlert} from "../../../Base/Alert/AlertReducer";

class FullViewPlayerContainer extends Component {
    render() {
        if (!this.props.songs || !this.props.musicPlayer) {
            return null;
        }
        let song = this.props.songs[this.props.musicPlayer.Album_ID].Songs[this.props.musicPlayer.Song_ID];

        let song_duration = this.props.songs[this.props.musicPlayer.Album_ID].Songs[this.props.musicPlayer.Song_ID].Song_Duration;

        let repeat_class;
        switch (this.props.musicPlayer.repeat) {
            case 0: repeat_class = 'off'; break;
            case 1: repeat_class = 'one'; break;
            case 2: repeat_class = 'all'; break;
            default: repeat_class = '';
        }

        return (
            <div className={'fullView'} id={'fullView'} onScroll={e => this.preventScroll(e)}>
                <FontAwesomeIcon
                    className={'fullView--close_icon'}
                    icon={'times'}
                    onClick={this.toggleFullView}
                />
                <img src={config.baseURL + config.imageURL + song.CoverImage} alt=""/>
                <div className="fullView--title">
                    <div
                        className="fullView--title--song">{this.props.songs[this.props.musicPlayer.Album_ID].Songs[this.props.musicPlayer.Song_ID].Song_Title}</div>
                    <div
                        className="fullView--title--album">{this.props.songs[this.props.musicPlayer.Album_ID].Album_Name}</div>
                </div>
                <div className={'fullView--controls'}>
                    <div className={'fullView--controls--buttons'}>
                        <div className={'repeat repeat--' + repeat_class} onClick={this.props.setRepeat}>
                            <FontAwesomeIcon icon={'repeat'} />
                        </div>
                        <FontAwesomeIcon className={'playerIcon'} icon={'backward-step'} onClick={this.props.prevSong}/>
                        <FontAwesomeIcon className={'playerIcon'} icon={this.props.musicPlayer.play ? 'pause' : 'play'}
                                         onClick={this.togglePlayPause}/>
                        <FontAwesomeIcon className={'playerIcon'} icon={'forward-step'} onClick={this.props.nextSong}/>
                        <div className="song_likes_div">
                            <FontAwesomeIcon
                                className={this.props.songs[this.props.musicPlayer.Album_ID].Songs[this.props.musicPlayer.Song_ID].Likes
                                && this.props.songs[this.props.musicPlayer.Album_ID].Songs[this.props.musicPlayer.Song_ID].Likes[this.props.userData.userID]
                                    ? 'favorite favorite--liked' : 'favorite'} icon={'heart'} onClick={this.likeSong}
                            />
                            <div>
                                {this.props.songs[this.props.musicPlayer.Album_ID].Songs[this.props.musicPlayer.Song_ID].Likes
                                    && Object.keys(this.props.songs[this.props.musicPlayer.Album_ID].Songs[this.props.musicPlayer.Song_ID].Likes).length > 0
                                    && Object.keys(this.props.songs[this.props.musicPlayer.Album_ID].Songs[this.props.musicPlayer.Song_ID].Likes).length}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'fullView--controls--slider'}>
                    <div
                        className={'fullView--controls--slider--songTime'}>{this.formatSongTime(this.props.audioTime)} / {this.formatSongTime(song_duration)}</div>
                    <input className={'slider'} type={'range'} value={this.props.audioTime} max={song_duration}
                           onChange={this.setSongtime}/>
                </div>
            </div>
        )
    }

    preventScroll = (e) => {
        e.preventDefault();
    }

    formatSongTime = (time) => {
        let min = Math.floor(time / 60);
        let sec = Math.floor(time % 60);
        if (sec < 10) sec = '0' + sec;
        return min + ':' + sec;
    }

    setSongtime = (e) => {
        this.props.setSliderTime(e.target.value);
    }

    toggleFullView = () => {
        this.props.toggleFullView();
    }

    togglePlayPause = () => {
        this.props.togglePlayPause(!this.props.musicPlayer.play);
    }

    likeSong = () => {
        if (this.props.userData.access === 1) {
            let fd = new FormData();
            let send_data = {
                Song_ID: this.props.musicPlayer.Song_ID,
                User_ID: this.props.userData.userID,
            }
            fd.append('data', JSON.stringify(send_data));
            this.props.saveData({
                Query     : 'likeSong',
                hidden    : true,
                formData  : fd,
                title     : 'Like wird gespeichert',
                errorTitle: 'Like konnte nicht gespeichert werden',
                callBack  : this.completeLike,
                response  : this.props.response,
            })
        } else {
            this.props.toggleAlert({
                header: 'Likes',
                text  : Translations.noUserSongLike[this.props.userData.Language],
            })
            setTimeout(() => {
                this.props.closeAlert();
            }, 3000)
        }
    }

    completeLike = (data) => {
        this.props.completeSaveLike({
            Album_ID: this.props.musicPlayer.Album_ID,
            Song_ID : data.Song_ID,
            User_ID : data.User_ID,
            type    : data.type,
        })
    }
}

const mapStateToProps = (state) => ({
    userData   : state.user.userData,
    songs      : state.music.songs,
    musicPlayer: state.musicPlayer,
})

const mapDispatchToProps = {
    saveData,
    response,
    toggleFullView,
    togglePlayPause,
    completeSaveLike,
    toggleAlert,
    closeAlert,
    setRepeat,
}

export default connect(mapStateToProps, mapDispatchToProps)(FullViewPlayerContainer);