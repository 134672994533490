import {createReducer, createAction} from "@reduxjs/toolkit";

//action

export const storeSongs = createAction('STORE_SONGS');
export const setAlbumDetail = createAction('SET_ALBUM_DETAIL');
export const resetAlbumDetail = createAction('RESET_ALBUM_DETAIL');
export const completeSaveLike = createAction('COMPLETE_SAVE_LIKE');
export const setNewPlayCount = createAction('SET_NEW_PLAY_COUNT');

//initialState

export const music = {
    songs: {},
    songList: {},
    albumDetail: {
        open: false,
        ID: null,
    }
}

//reducer

const MusicReducer = createReducer(music, builder => {
    builder
        .addCase(storeSongs, (state, action) => {
            state.songs = action.payload;
        })
        .addCase(setAlbumDetail, (state,action) => {
            state.albumDetail.open = true;
            state.albumDetail.ID = action.payload;
        })
        .addCase(resetAlbumDetail, (state) => {
            state.albumDetail = {
                open: false,
                ID: null,
            }
        })
        .addCase(completeSaveLike, (state,action) => {
            if(action.payload.type === 'add') {
                if(!state.songs[action.payload.Album_ID].Songs[action.payload.Song_ID].Likes) {
                    state.songs[action.payload.Album_ID].Songs[action.payload.Song_ID]['Likes'] = {};
                }
                state.songs[action.payload.Album_ID].Songs[action.payload.Song_ID]['Likes'][action.payload.User_ID] = {
                    User_ID: action.payload.User_ID,
                }
            }else if(action.payload.type === 'remove') {
                delete state.songs[action.payload.Album_ID].Songs[action.payload.Song_ID].Likes[action.payload.User_ID];
            }
        })
        .addCase(setNewPlayCount, (state,action) => {
            state.songs[action.payload.Album_ID].Songs[action.payload.Song_ID].Play_Count = action.payload.newCount;
        })
})

export default MusicReducer;