import React, {Component} from "react";
import {connect} from "react-redux";
import {loadData} from "../Base/Functions/Functions";
import {response} from "../Base/Modal/ModalReducer";
import {storeSongs} from "./MusicReducer";
import {setSong} from "./MusicPlayer/MusicPlayerReducer";
import AlbumContainer from "./AlbumContainer";
import './music.scss';
import AlbumDetail from "./AlbumDetail/AlbumDetail";
import Translations from "../Base/Translations";
import config from "../Base/config";

class MusicContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            detail: false,
        }

        document.title = 'Alex Hinterleitner | Music';
    }

    componentDidMount() {
        this.loadSongs();
    }

    render() {

        if (this.props.albumDetail.open) {
            setTimeout(() => {
                this.setState({
                    detail: true,
                })
            }, 500);
        } else {
            if (this.state.detail) {
                this.setState({
                    detail: false,
                })
            }
        }

        return (
            <>
                <div className={'music_container'} style={{backgroundImage: 'url(' + config.baseURL + config.imageURL + 'Tirol2.jpg)'}}>
                    {/*<h1>Music</h1>*/}
                    <div
                        className={this.props.albumDetail.open ? "album_container_div album_container_div--open" : "album_container_div"}>
                        {
                            Object.keys(this.props.songs).map(album => {
                                return <AlbumContainer key={album} albumData={this.props.songs[album]}/>
                            })
                        }
                    </div>
                    {this.props.albumDetail.open &&
                        <div className={"album_detail_div"}>
                            <AlbumDetail detail={this.props.albumDetail.open}/>
                        </div>
                    }
                    {/*{this.props.userData.userRights === 10 &&*/}
                    {/*    <div className="add_song_button">*/}
                    {/*        <FontAwesomeIcon icon={'plus-circle'} onClick={this.props.toggleEditMenu}/>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {/*{this.props.editMenu.open &&*/}
                    {/*    <EditMenuContainer/>*/}
                    {/*}*/}
                </div>
            </>
        )
    }

    loadSongs = () => {
        this.props.loadData({
            Query     : 'loadSongs',
            hidden    : true,
            title     : Translations.songsLoading[this.props.userData.Language],
            errorTitle: Translations.songsLoadingError[this.props.userData.Language],
            callBack  : this.props.storeSongs,
            response  : this.props.response,
        })
    }

    setSong = (Song_ID) => {
        this.props.setSong(this.props.songs[Song_ID]);
    }
}

const mapStateToProps = (state) => ({
    userData   : state.user.userData,
    songs      : state.music.songs,
    editMenu   : state.editMenu,
    albumDetail: state.music.albumDetail,
})

const mapDispatchToProps = {
    loadData,
    response,
    storeSongs,
    setSong,
}

export default connect(mapStateToProps, mapDispatchToProps)(MusicContainer);