import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {loadData, saveData, sortObjectToReverseArray} from "../Base/Functions/Functions";
import {response} from "../Base/Modal/ModalReducer";
import Translations from "../Base/Translations";
import './bawe.scss';
import BAWEListContainer from "./BAWEListContainer";
import {storeBAWEList, completeAddNewWord, storeBAWEAuthors} from "./BAWEReducer";
import PropTypes from "prop-types";
import Input from "../Base/Components/Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InputCheckbox from "../Base/Components/InputCheckbox";
import config from "../Base/config";
import {toggleAlert, closeAlert} from "../Base/Alert/AlertReducer";
import LikeOverlay from "./LikeOverlay";


const BAWEHome = (props) => {
    const [newWord, setNewWord] = useState('');
    const [bad, setBad] = useState(false);
    const [scrollWordID, setScrollWordID] = useState(null);
    const [search, setSearch] = useState('');
    const [statsOpen, setStatsOpen] = useState(false);

    useEffect(() => {
        initialLoad();
    }, [])

    const initialLoad = () => {
        document.title = 'Alex Hinterleitner | BAWE';
        props.loadData({
            Query     : 'loadBAWEList',
            title     : 'Loading',
            errorTitle: Translations.errorOccured[props.userData.Language],
            callBack  : props.storeBAWEList,
            response  : props.response,
        })
        props.loadData({
            Query     : 'loadBAWEAuthors',
            title     : 'Loading',
            errorTitle: Translations.errorOccured[props.userData.Language],
            callBack  : props.storeBAWEAuthors,
            response  : props.response,
        })
    }

    const handleNewWordSubmit = (e) => {
        if (newWord.trim().toString().length > 0) {
            if (e.key === 'Enter') {
                let word_exists = false;
                for (let i in props.BAWEList) {
                    if (props.BAWEList[i].word.trim() === newWord.trim()) {
                        word_exists = true;
                    }
                }
                if (word_exists) {
                    props.toggleAlert({
                        header: Translations.duplicate[props.userData.Language] + '!',
                        text  : Translations.entryAlreadyExists[props.userData.Language] + '!',
                    })
                    setTimeout(() => {
                        props.closeAlert();
                    }, 3000)
                } else {
                    let fd = new FormData();
                    let send_data = {
                        userID: props.userData.userID,
                        word  : newWord.trim(),
                        bad   : Number(bad),
                    };
                    fd.append('data', JSON.stringify(send_data));

                    props.saveData({
                        Query     : 'BAWE_add_new_word',
                        formData  : fd,
                        title     : '',
                        errorTitle: '',
                        callBack  : completeAddNewWord,
                        response  : props.response,
                        hidden    : true,
                    })
                    setNewWord('');
                }
            }
        }
    }

    const completeAddNewWord = (data) => {
        props.socket.send(JSON.stringify({
            type: 'BAWE_add_new_word',
            data,
        }));
        props.completeAddNewWord(data);
    }

    const onListScroll = (e) => {
        const scrollButton = document.getElementById('scrollButton');
        scrollButton.style.opacity = (Number(e.target.scrollTop - 200) / 5000).toString();
    }

    const scrollToTop = () => {
        const main = document.getElementById('bawe__main');
        main.scrollTop = 0;
    }

    const setScrollID = (Word_ID) => {
        setScrollWordID(Word_ID);
        setTimeout(() => {
            setScrollWordID(null);
        }, 500)
    }

    const shareWotd = () => {
        if(navigator.share) {
            navigator.share({
                title: 'Mein BAWE-Wort des Tages',
                text: `Mein BAWE-Wort des Tages ist ${props.BAWEList[props.userData.WordOfTheDay].word}.\n\nDu möchtest auch jeden Tag ein neues österreichisches Wort zu deinem Wortschatz hinzufügen und herausfinden, was dieses und viele andere Wörter bedeuten?\n\nSchau vorbei auf https://alexhinterleitner.com/BAWE`,
            })
                .then(() => console.log('success'))
                .catch((err) => console.error(err));
        }
    }

    if (!props.BAWEList) return null;
    if (Object.keys(props.BAWEList).length === 0) return null;

    const filtered_list = sortObjectToReverseArray(props.BAWEList).filter((item) => (
        item.word.toLowerCase().includes(search.toLowerCase()) ||
        (item.Description && Object.values(item.Description).length > 0 && Object.values(item.Description).some((desc) => (
                desc.Description.toLowerCase().includes(search.toLowerCase())
            ))
        )
    ));

    return (
        <div className={'bawe bawe__main'} id={'bawe__main'} onScroll={onListScroll}>
            <div className="bawe__main__scrollTopButton" id={'scrollButton'} onClick={scrollToTop}>
                <FontAwesomeIcon icon={'chevron-up'}/>
            </div>
            {props.like_overlay.open &&
                <LikeOverlay/>
            }
            <div className={'bawe__main__content'}>
                <div className="bawe__main__header">
                    <div className="bawe__main__header__head">
                        <div className="bawe__main__header__head__logo">
                            <a href={config.serverBaseURL + '/BAWE'}>
                                <img src={config.baseURL + config.imageURL + '/bawe-logo-v1.png'} alt="BAWE Logo"
                                     width={250}/>
                            </a>
                        </div>
                        {!config.isMobile.any() &&
                            <div className="bawe__main__header__head__search">
                                <div
                                    className="bawe__main__header__head__search__icon bawe__main__header__head__search__icon__search">
                                    <FontAwesomeIcon icon={'search'}/>
                                </div>
                                <Input
                                    type={'text'}
                                    name={'search'}
                                    placeholder={Translations.searchFor[props.userData.Language] + '...'}
                                    onChange={(key, value) => setSearch(value)}
                                    value={search}
                                />
                                <div
                                    className="bawe__main__header__head__search__icon bawe__main__header__head__search__icon__reset"
                                    onClick={() => setSearch('')}>
                                    <FontAwesomeIcon icon={'times'}/>
                                </div>
                            </div>
                        }
                    </div>
                    {props.BAWEList && props.userData.WordOfTheDay && props.BAWEList[props.userData.WordOfTheDay] &&
                        <div className="bawe__main__header__wotd">
                            {/*{config.isMobile.any() &&*/}
                                <img className={'bawe__main__header__wotd__image'}
                                     src={config.baseURL + config.imageURL + '/Element-BAWE.png'} alt={'BAWE_Mascott'}
                                     width={150}/>
                            {/*}*/}
                            <div className="bawe__main__header__wotd__container">
                                <h2 className="bawe__main__header__wotd__container__header">{Translations.yourWordOfTheDay[props.userData.Language]}</h2>
                                <div className="bawe__main__header__wotd__container__word_desc_container" onClick={shareWotd}
                                     /*onClick={() => setScrollID(props.BAWEList[props.userData.WordOfTheDay].Word_ID)}*/>
                                    <div className="bawe__main__header__wotd__container__word">
                                        {props.BAWEList[props.userData.WordOfTheDay].word}
                                        {props.userList[props.BAWEList[props.userData.WordOfTheDay].User_ID] &&
                                            <img
                                                src={config.baseURL + config.imageURL + '/AT_Flags/' + props.userList[props.BAWEList[props.userData.WordOfTheDay].User_ID].Flag_dir}
                                                alt={props.userList[props.BAWEList[props.userData.WordOfTheDay].User_ID].Bundesland + ' Flagge'}
                                                width={10}/>
                                        }
                                    </div>
                                    <div className="bawe__main__header__wotd__container__desc">
                                        {props.BAWEList[props.userData.WordOfTheDay].Description && Object.keys(props.BAWEList[props.userData.WordOfTheDay].Description).length > 0 &&
                                            Object.values(props.BAWEList[props.userData.WordOfTheDay].Description).map((item) => (
                                                <>
                                                    <div>{item.Description}</div>
                                                    {/*{props.userList && item.User_ID && props.userList[item.User_ID] &&*/}
                                                    {/*    <img src={config.baseURL + config.imageURL + '/AT_Flags/' + props.userList[item.User_ID].Flag_dir} alt={props.userList[item.User_ID].Bundesland + ' Flagge'} width={10} />*/}
                                                    {/*}*/}
                                                </>
                                            ))
                                        }
                                    </div>
                                    <div className="bawe__main__header__wotd__container__user">
                                        {props.userList[props.BAWEList[props.userData.WordOfTheDay].User_ID] && props.userList[props.BAWEList[props.userData.WordOfTheDay].User_ID].PreName + ' ' + props.userList[props.BAWEList[props.userData.WordOfTheDay].User_ID].LastName}
                                    </div>
                                    {navigator.share &&
                                        <div className="bawe__main__header__wotd__container__like">
                                            <FontAwesomeIcon icon={'share-alt'} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {props.userData.access !== 0 &&
                        <div className="bawe__main__header__options">
                            {/*<div className="bawe__main__header__options__infoHead">*/}
                            {/*    Eine Sammlung österreichischer Wörter, die hier archiviert werden, um nicht in Vergessenheit zu geraten.*/}
                            {/*</div>*/}
                            <div className="bawe__main__header__options__stats">
                                <div className="bawe__main__header__options__stats__authors">
                                    <div className={'bawe__main__header__options__stats__authors__header'}
                                         onClick={() => setStatsOpen(!statsOpen)}>
                                        <span
                                            className="color--darkred">{Object.keys(props.BAWEList).length}</span> Beiträge
                                        von <span
                                        className="color--darkred">{Object.keys(props.authors).length}</span> woschechtn
                                    </div>
                                    {statsOpen &&
                                        <div className={"bawe__main__header__options__stats__authors__container"}>
                                            <div
                                                className={statsOpen ? "bawe__main__header__options__stats__authors__container__list bawe__main__header__options__stats__authors__container__list--opened" : "bawe__main__header__options__stats__authors__container__list bawe__main__header__options__stats__authors__container__list--closed"}>
                                                {Object.keys(props.authors).map(user_id => (
                                                    props.userList && props.userList[user_id] &&
                                                    <div
                                                        className="bawe__main__header__options__stats__authors__container__list__elem">
                                                        <div>
                                                            <img
                                                                src={config.baseURL + config.imageURL + '/AT_Flags/' + props.userList[user_id].Flag_dir}
                                                                alt={props.userList[user_id].Bundesland + ' Flagge'}
                                                                width={10}/>
                                                            <div>{props.userList[user_id].PreName + ' ' + props.userList[user_id].LastName}</div>
                                                        </div>
                                                        <div>
                                                            <span>{props.authors[user_id]}</span> Wörter
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="bawe__main__header__options__add">
                                <h4>{Translations.addNewWord[props.userData.Language]}</h4>
                                <div className="bawe__main__header__options__addWord">
                                    <Input
                                        type={'text'}
                                        name={'newWord'}
                                        placeholder={Translations.addNewWord[props.userData.Language] + '...'}
                                        onChange={(key, value) => setNewWord(value)}
                                        onKeyDown={handleNewWordSubmit}
                                        value={newWord}
                                    />
                                    <div className="bawe__main__header__options__addWord__add_icon"
                                         onClick={() => handleNewWordSubmit({ key: 'Enter' })}>
                                        <FontAwesomeIcon icon={'plus'}/>
                                    </div>
                                </div>
                                <div className="bawe__main__header__options__addWord__bad">
                                    <InputCheckbox
                                        label={Translations.badWord[props.userData.Language]}
                                        checked={bad}
                                        onChange={() => setBad(!bad)}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {config.isMobile.any() &&
                        <div className="bawe__main__header__head__search">
                            <div
                                className="bawe__main__header__head__search__icon bawe__main__header__head__search__icon__search">
                                <FontAwesomeIcon icon={'search'}/>
                            </div>
                            <Input
                                type={'text'}
                                name={'search'}
                                placeholder={Translations.searchFor[props.userData.Language] + '...'}
                                onChange={(key, value) => setSearch(value)}
                                value={search}
                            />
                            <div
                                className="bawe__main__header__head__search__icon bawe__main__header__head__search__icon__reset"
                                onClick={() => setSearch('')}>
                                <FontAwesomeIcon icon={'times'}/>
                            </div>
                        </div>
                    }
                </div>
                <div className="list__container">
                    <BAWEListContainer filtered_list={filtered_list} BAWEList={props.BAWEList}
                                       scrollWordID={scrollWordID} socket={props.socket}/>
                </div>
            </div>
        </div>
    )
}

BAWEHome.propTypes = {
    loadData          : PropTypes.func,
    saveData          : PropTypes.func,
    response          : PropTypes.func,
    storeBAWEList     : PropTypes.func,
    userData          : PropTypes.object,
    BAWEList          : PropTypes.object,
    userList          : PropTypes.object,
    completeAddNewWord: PropTypes.func,
    toggleAlert       : PropTypes.func,
    closeAlert        : PropTypes.func,
    storeBAWEAuthors  : PropTypes.func,
    authors           : PropTypes.object,
}

const mapStateToProps = (state) => ({
    userData    : state.user.userData,
    userList    : state.user.userList,
    BAWEList    : state.BAWE.list,
    like_overlay: state.BAWE.like_overlay,
    authors     : state.BAWE.authors,
})

const mapDispatchToProps = {
    loadData,
    saveData,
    response,
    storeBAWEList,
    completeAddNewWord,
    toggleAlert,
    closeAlert,
    storeBAWEAuthors,
}

export default connect(mapStateToProps, mapDispatchToProps)(BAWEHome)