import React from "react";
import {connect} from "react-redux";
import {storeUser, storeBundeslaender, storeUserList} from "./UserReducer";
import MainMenu from "./MainMenu/MainMenu";
import {Route, Routes} from "react-router-dom";
import About from "../About/About";
import MusicPlayerContainer from "../Music/MusicPlayer/MusicPlayerContainer";
import MusicContainer from "../Music/MusicContainer";
import './Styles/styles.scss';
import {loadData} from "./Functions/Functions";
import UserContainer from "../User/UserContainer";
import HomeContainer from "../Home/HomeContainer";
import ModalContainer from "./Modal/ModalContainer";
import {response} from "./Modal/ModalReducer";
import Translations from "./Translations";
import AlertContainer from "./Alert/AlertContainer";
import FullViewPlayerContainer from "../Music/MusicPlayer/FullViewPlayer/FullViewPlayerContainer";
import Impressum from "./Footer/Impressum";
import PrivacyPolicy from "./Footer/PrivacyPolicy";
import MusiciansContainer from "../Musicians/MusiciansContainer";
import TeslaGraz from "../OtherApps/TeslaGraz";
import BAWEHome from "../BAWE/BAWEHome";
import PropTypes from "prop-types";
import Redirect from "./Redirect";
import BAWENotifications from "../BAWE/BAWENotifications";
import Test from "./Test";
import {
    completeNewComment,
    completeDeleteComment,
    completeLike,
    completeAddNewWord,
    completeDeleteWord,
    completeAddDescription,
    completeDeleteDescription,
    toggleCheckNotifications,
    completeCommentLike,
} from "../BAWE/BAWEReducer";

class AppContent extends React.Component {
    constructor(props) {
        super(props);

        let lang = navigator.language.slice(0, 2).toUpperCase();

        this.state = {
            scrollTop : 0,
            Language  : lang === 'EN' || lang === 'DE' ? lang : 'EN',
            audioTime : 0,
            sliderTime: 0,
            nextSong  : false,
            prevSong  : false,
            files     : [],
        }
    }

    componentDidMount() {
        this.initial_load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.wssData !== prevProps.wssData) {
            if (this.props.wssData && Object.keys(this.props.wssData).length > 0 && this.props.wssData.type && this.props.wssData.data && Object.keys(this.props.wssData.data).length > 0) {
                this.props.toggleCheckNotifications(true);
                switch (this.props.wssData.type) {
                    case 'BAWE_Comment_new': {
                        this.props.completeNewComment(this.props.wssData.data);
                        break;
                    }
                    case 'BAWE_Comment_delete': {
                        this.props.completeDeleteComment(this.props.wssData.data);
                        break;
                    }
                    case 'BAWE_Like': {
                        this.props.completeLike(this.props.wssData.data);
                        break;
                    }
                    case 'BAWE_add_new_word': {
                        this.props.completeAddNewWord(this.props.wssData.data);
                        break;
                    }
                    case 'BAWE_delete_word': {
                        this.props.completeDeleteWord(this.props.wssData.data);
                        break;
                    }
                    case 'BAWE_add_description': {
                        this.props.completeAddDescription(this.props.wssData.data);
                        break;
                    }
                    case 'BAWE_delete_description': {
                        this.props.completeDeleteDescription(this.props.wssData.data);
                        break;
                    }
                    case 'BAWE_like_comment': {
                        this.props.completeCommentLike(this.props.wssData.data);
                        break;
                    }
                    default:
                }
            }
        }
    }

    render() {

        const ext_routes = ['teslaGraz'];

        return (
            <>
                {this.props.modal.active &&
                    <ModalContainer/>
                }
                <MainMenu/>
                <div className='main' onScroll={this.toggleScroll}>
                    <Routes>
                        <Route path={'/'} element={<HomeContainer scrollTop={this.state.scrollTop}/>}/>
                        <Route path={'/About'} element={<About/>}/>
                        <Route path={'/Music'} element={<MusicContainer/>}/>
                        <Route path={'/User'} element={<UserContainer/>}/>
                        <Route path={'/Impressum'} element={<Impressum/>}/>
                        <Route path={'/PrivacyPolicy'} element={<PrivacyPolicy/>}/>
                        <Route path={'/teslaReact'} element={<TeslaGraz/>}/>
                        <Route path={'/BAWE'} element={<BAWEHome socket={this.props.socket}/>}/>
                        <Route path={'/BAWEList'} element={<BAWEHome socket={this.props.socket}/>}/>
                        <Route path={'/BAWE_Notifications'} element={<BAWENotifications/>}/>
                        <Route path={'/Musicians'} element={<MusiciansContainer/>}/>
                        <Route path={'/Test'} element={<Test socket={this.props.socket}/>}/>
                        {ext_routes.map((route, index) => (
                            <Route path={'/' + route} element={<Redirect route={route}/>}/>))}
                    </Routes>

                    <MusicPlayerContainer setAudioTime={this.setAudioTime} sliderTime={this.state.sliderTime}
                                          nextSong={this.state.nextSong} resetNextSong={this.resetNextSong}
                                          prevSong={this.state.prevSong} resetPrevSong={this.resetPrevSong}
                                          dir={this.props.musicPlayer.dir} songName={this.props.musicPlayer.songName}/>

                    {this.props.musicPlayer.fullView &&
                        <FullViewPlayerContainer audioTime={this.state.audioTime} setSliderTime={this.setSliderTime}
                                                 nextSong={this.nextSong} prevSong={this.prevSong}/>
                    }

                    {/*<Footer />*/}
                </div>
                {this.props.alert.open &&
                    <AlertContainer/>
                }

            </>
        )
    }

    nextSong = () => {
        this.setState({
            nextSong: true,
        })
    }

    resetNextSong = () => {
        this.setState({
            nextSong: false,
        })
    }

    prevSong = () => {
        this.setState({
            prevSong: true,
        })
    }

    resetPrevSong = () => {
        this.setState({
            prevSong: false,
        })
    }

    setAudioTime = (time) => {
        this.setState({
            audioTime: time,
        })
    }

    setSliderTime = (time) => {
        this.setState({
            sliderTime: time,
        })
    }

    toggleScroll = (e) => {
        this.setState({
            scrollTop: e.target.scrollTop,
        })
    }

    initial_load = () => {
        this.props.loadData({
            Query     : 'loadUser',
            hidden    : true,
            title     : Translations.userLoading[this.state.Language],
            errorTitle: Translations.userLoadingError[this.state.Language],
            callBack  : this.props.storeUser,
            response  : this.props.response,
        })
        this.props.loadData({
            Query     : 'loadBundeslaender',
            hidden    : true,
            title     : Translations.userLoading[this.state.Language],
            errorTitle: Translations.userLoadingError[this.state.Language],
            callBack  : this.props.storeBundeslaender,
            response  : this.props.response,
        })
        this.props.loadData({
            Query     : 'loadAllUsers',
            title     : '',
            errorTitle: '',
            callBack  : this.props.storeUserList,
            response  : this.props.response,
            hidden    : true,
        })
        this.props.loadData({
            Query     : 'test',
            title     : '',
            errorTitle: '',
            callBack  : () => null,
            response  : this.props.response,
            hidden    : true,
        })
    }

    storeAudioFiles = (data) => {
        this.setState({
            files: data,
        })
    }
}

AppContent.propTypes = {
    user                     : PropTypes.object,
    musicPlayer              : PropTypes.object,
    modal                    : PropTypes.object,
    alert                    : PropTypes.func,
    storeUser                : PropTypes.func,
    loadData                 : PropTypes.func,
    response                 : PropTypes.func,
    storeBundeslaender       : PropTypes.func,
    completeNewComment       : PropTypes.func,
    completeDeleteComment    : PropTypes.func,
    completeLike             : PropTypes.func,
    completeAddNewWord       : PropTypes.func,
    completeDeleteWord       : PropTypes.func,
    completeAddDescription   : PropTypes.func,
    completeDeleteDescription: PropTypes.func,
    toggleCheckNotifications : PropTypes.func,
    completeCommentLike      : PropTypes.func,
}

const mapStateToProps = (state) => ({
    user       : state.user,
    musicPlayer: state.musicPlayer,
    modal      : state.modal,
    alert      : state.alert,
})

const mapDispatchToProps = {
    storeUser,
    loadData,
    response,
    storeBundeslaender,
    storeUserList,
    completeNewComment,
    completeDeleteComment,
    completeLike,
    completeAddNewWord,
    completeDeleteWord,
    completeAddDescription,
    completeDeleteDescription,
    toggleCheckNotifications,
    completeCommentLike,
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContent);
