import UserReducer from "./UserReducer.js";
import ModalReducer from "./Modal/ModalReducer";
import MusicPlayerReducer from "../Music/MusicPlayer/MusicPlayerReducer";
import MusicReducer from "../Music/MusicReducer";
import AlertReducer from "./Alert/AlertReducer";
import BAWEReducer from "../BAWE/BAWEReducer";

const rootReducer = (state = {}, action) => {
    return {
        user       : UserReducer(state.user, action),
        modal      : ModalReducer(state.modal, action),
        music      : MusicReducer(state.music, action),
        musicPlayer: MusicPlayerReducer(state.musicPlayer, action),
        alert      : AlertReducer(state.alert, action),
        BAWE       : BAWEReducer(state.BAWE, action),
    }
}

export default rootReducer;