import {createReducer, createAction} from "@reduxjs/toolkit";

//action

export const response = createAction('RESPONSE');
export const closeModal = createAction('CLOSE_MODAL');
export const openEditModal = createAction('OPEN_EDIT_MODAL');

//initialState

export const modal = {
    active      : false,
    type        : '',
    title       : '',
    text        : '',
    duration    : '',
    count       : 0,
    edit        : false,
    editType    : '',
    confirmColor: 'red',
    cancelColor : 'grey',
}

//reducer

const ModalReducer = createReducer(modal, builder => {
    builder
        .addCase('LOAD_DATA', (state, action) => {
            if (!action.hidden) {
                state.active = true;
                state.type = 'loadData';
                state.title = action.title;
                state.count = state.count + 1;
            } else {
                state.active = false;
            }
        })
        .addCase('SAVE_DATA', (state, action) => {
            if (!action.hidden) {
                state.active = true;
                state.type = 'loadData';
                state.title = action.title;
                state.count = state.count + 1;
            } else {
                state.active = false;
            }
        })
        .addCase(response, (state, action) => {
            if (!action.payload.active && state.count === 1) {
                state.active = modal.active;
                state.type = modal.type;
                state.title = modal.title;
                state.text = modal.text;
                state.duration = modal.duration;
                state.count = modal.count;
            } else if (!action.payload.active && state.count > 1) {
                state.count = state.count - 1
            } else {
                state.type = action.payload.type;
                state.title = action.payload.title;
                state.text = action.payload.text;
            }
        })
        .addCase(closeModal, (state) => {
            state.active = false;
            state.edit = false;
            state.count = 0;
            state.title = '';
            state.text = '';
        })
        .addCase(openEditModal, (state, action) => {
            state.active = true;
            state.edit = true;
            state.editType = action.payload.editType;
            state.title = action.payload.title;
            state.text = action.payload.text;
            state.confirmColor = action.payload.confirmColor;
            state.cancelColor = action.payload.cancelColor;
        })
})

export default ModalReducer;