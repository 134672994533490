import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Translations from "../Base/Translations";
import React from "react";

const UserOverviewMenu = (props) => {
    return (
        <div className="user_overview--menu">
            <div className="user_overview--menu--elem" onClick={() => props.setDetail('account')}>
                <FontAwesomeIcon icon={'user'}/>
                {Translations.myAccount[props.userData.Language]}
            </div>
            <div className="user_overview--menu--elem" onClick={() => props.setDetail('lang')}>
                <FontAwesomeIcon icon={'globe-europe'}/>
                {Translations.language[props.userData.Language]}
            </div>
            <div className="user_overview--menu--elem" onClick={() => props.setDetail('change_password')}>
                <FontAwesomeIcon icon={'key'}/>
                {Translations.changePassword[props.userData.Language]}
            </div>
            <div className="user_overview--menu--elem" onClick={props.logout}>
                <FontAwesomeIcon icon={'sign-out-alt'}/>
                Logout
            </div>
        </div>
    )
}

export default UserOverviewMenu;