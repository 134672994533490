import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatDateTime} from "../Base/Functions/Functions";

const BAWENotificationElement = (props) => {
    if (!props.userList) return null;
    if (!props.BAWElist) return null;
    if (!props.listitem) return null;

    let text = '';

    const word = props.BAWElist &&
        Object.keys(props.BAWElist).length > 0
        && props.listitem.Word_ID
        && props.BAWElist[props.listitem.Word_ID]
        && props.BAWElist[props.listitem.Word_ID].word;
    const word_id = props.BAWElist
        && Object.keys(props.BAWElist).length > 0
        && props.listitem.Word_ID
        && props.BAWElist[props.listitem.Word_ID]
        && props.BAWElist[props.listitem.Word_ID].Word_ID;
    const word_obj = props.BAWElist
        && Object.keys(props.BAWElist).length > 0
        && props.listitem.Word_ID
        && props.BAWElist[props.listitem.Word_ID];
    const datetime = props.listitem &&
        props.listitem.datetime;

    switch (props.listitem.type) {
        case 'Description': {
            const description = word_obj.Description
                && Object.keys(word_obj.Description).length > 0
                && word_obj.Description[props.listitem.Description_ID]
                && word_obj.Description[props.listitem.Description_ID].Description;
            const user = word_obj.Description
                && Object.keys(word_obj.Description).length > 0
                && word_obj.Description[props.listitem.Description_ID]
                && word_obj.Description[props.listitem.Description_ID].User_ID
                && props.userList[word_obj.Description[props.listitem.Description_ID].User_ID]
                && props.userList[word_obj.Description[props.listitem.Description_ID].User_ID].PreName + ' ' + props.userList[word_obj.Description[props.listitem.Description_ID].User_ID].LastName;

            if(props.language === 'DE') {
                text = <div className="bn_main__list__elem__item__text">
                    <FontAwesomeIcon icon={'pen'}/>
                    <div>
                        <span className={'user'}>{user}</span> hat die Beschreibung <span className={'desc'}>"{description}"</span> zu deinem
                        Eintrag <span className={'word'}>{word}</span> hinzugefügt.
                    </div>
                </div>
            }else{
                text = <div className="bn_main__list__elem__item__text">
                    <FontAwesomeIcon icon={'pen'}/>
                    <div>
                        <span className={'user'}>{user}</span> added a description <span className={'desc'}>"{description}"</span> to your
                        word <span className={'word'}>{word}</span>
                    </div>
                </div>
            }
        } break;
        case 'Like': {
            const user = word_obj &&
                word_obj.Likes
                && Object.keys(word_obj.Likes).length > 0
                && word_obj.Likes[props.listitem.Likes_ID]
                && word_obj.Likes[props.listitem.Likes_ID].User_ID
                && props.userList[word_obj.Likes[props.listitem.Likes_ID].User_ID]
                && props.userList[word_obj.Likes[props.listitem.Likes_ID].User_ID].PreName + ' ' + props.userList[word_obj.Likes[props.listitem.Likes_ID].User_ID].LastName;

            if(props.language === 'DE') {
                text =
                    <div className="bn_main__list__elem__item__text">
                        <FontAwesomeIcon icon={'thumbs-up'} />
                        <div>
                            <span className={'user'}>{user}</span> gefällt dein Wort <span className={'word'}>{word}</span>.
                        </div>
                    </div>
            }else{
                text =
                    <div className="bn_main__list__elem__item__text">
                        <FontAwesomeIcon icon={'thumbs-up'} />
                        <div>
                            <span className={'user'}>{user}</span> liked your word <span className={'word'}>{word}</span>.
                        </div>
                    </div>
            }
        } break;
        case 'Comment': {
            const comment = word_obj.Comments
                && Object.keys(word_obj.Comments).length > 0
                && word_obj.Comments[props.listitem.Comments_ID]
                && word_obj.Comments[props.listitem.Comments_ID].Comment;
            const user = word_obj.Comments
                && Object.keys(word_obj.Comments).length > 0
                && word_obj.Comments[props.listitem.Comments_ID]
                && word_obj.Comments[props.listitem.Comments_ID].User_ID
                && props.userList[word_obj.Comments[props.listitem.Comments_ID].User_ID]
                && props.userList[word_obj.Comments[props.listitem.Comments_ID].User_ID].PreName + ' ' + props.userList[word_obj.Comments[props.listitem.Comments_ID].User_ID].LastName;

            if(props.language === 'DE') {
                text =
                    <div className="bn_main__list__elem__item__text">
                        <FontAwesomeIcon icon={'comment'}/>
                        <div>
                            <span className={'user'}>{user}</span> hat das Wort <span className={'word'}>{word}</span> mit <span className={'comment'}>"{comment}"</span> kommentiert.
                        </div>
                    </div>
            }else{
                text =
                    <div className="bn_main__list__elem__item__text">
                        <FontAwesomeIcon icon={'comment'}/>
                        <div>
                            <span className={'user'}>{user}</span> commented <span className={'comment'}>"{comment}"</span> on the word <span className={'word'}>{word}</span>.
                        </div>
                    </div>
            }
        } break;
        case 'Comment_Like': {
            const comment = word_obj.Comments
                && Object.keys(word_obj.Comments).length > 0
                && word_obj.Comments[props.listitem.Comment_Likes_Comment_ID]
                && word_obj.Comments[props.listitem.Comment_Likes_Comment_ID].Comment;
            const user = word_obj.Comments
                && Object.keys(word_obj.Comments).length > 0
                && word_obj.Comments[props.listitem.Comment_Likes_Comment_ID]
                && word_obj.Comments[props.listitem.Comment_Likes_Comment_ID].Comment_Likes
                && word_obj.Comments[props.listitem.Comment_Likes_Comment_ID].Comment_Likes[props.listitem.Comment_Likes_ID]
                && word_obj.Comments[props.listitem.Comment_Likes_Comment_ID].Comment_Likes[props.listitem.Comment_Likes_ID].User_ID
                && props.userList[word_obj.Comments[props.listitem.Comment_Likes_Comment_ID].Comment_Likes[props.listitem.Comment_Likes_ID].User_ID]
                && props.userList[word_obj.Comments[props.listitem.Comment_Likes_Comment_ID].Comment_Likes[props.listitem.Comment_Likes_ID].User_ID].PreName + ' ' + props.userList[word_obj.Comments[props.listitem.Comment_Likes_Comment_ID].Comment_Likes[props.listitem.Comment_Likes_ID].User_ID].LastName;

            if(props.language === 'DE') {
                text =
                    <div className="bn_main__list__elem__item__text">
                        <FontAwesomeIcon icon={'heart'} />
                        <div>
                            <span className={'user'}>{user}</span> gefällt dein Kommentar <span className={'comment'}>"{comment}"</span> zum Wort <span className="word">{word}</span>.
                        </div>
                    </div>
            }else{
                text =
                    <div className="bn_main__list__elem__item__text">
                        <FontAwesomeIcon icon={'heart'} />
                        <div>
                            <span className={'user'}>{user}</span> liked your comment <span className={'comment'}>"{comment}"</span> on the word <span className={'word'}>{word}</span>.
                        </div>
                    </div>
            }
        } break;
        case 'System_Notification': {
            text =
                <div className="bn_main__list__elem__item__text">
                    <FontAwesomeIcon icon={'info'} />
                    <div>
                        <span className={'color--orangered'}>[BAWE]</span> {props.listitem.System_Notification}.
                    </div>
                </div>
            // if(props.language === 'DE') {
            //     text =
            //         <div className="bn_main__list__elem__item__text">
            //             <FontAwesomeIcon icon={'comment'}/>
            //             <div>
            //                 <span className={'user'}>[BAWE]</span> {props.listitem.System_Notification}.
            //             </div>
            //         </div>
            // }else{
            //     text =
            //         <div className="bn_main__list__elem__item__text">
            //             <FontAwesomeIcon icon={'comment'}/>
            //             <div>
            //                 <span className={'user'}>{user}</span> commented <span className={'comment'}>"{comment}"</span> on the word <span className={'word'}>{word}</span>.
            //             </div>
            //         </div>
            // }
        } break;
        default:
    }

    return (
        <NavLink to={'/BAWE?Word_ID=' + word_id}>
            <div className="bn_main__list__elem">{
                <div className="bn_main__list__elem__item">
                    {text}
                    <div className="bn_main__list__elem__item__datetime">
                        {formatDateTime(datetime, false)}
                    </div>
                </div>
            }</div>
        </NavLink>
    )
}

BAWENotificationElement.propTypes = {
    userList: PropTypes.object,
    BAWElist: PropTypes.object,
}

const mapStateToProps = (state) => ({
    userList: state.user.userList,
    BAWElist: state.BAWE.list,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BAWENotificationElement)