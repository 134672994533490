import {connect} from "react-redux";
import BAWEListElem from "./BAWEListElem";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const BAWEListContainer = (props) => {
    const [scrollWordID, setScrollWordID] = useState(null);

    const [sortType, setSortType] = useState('');
    const [sortedFilteredList, setSortedFilteredList] = useState([]);

    useEffect(() => {
        if(props.scrollWordID) {
            setScrollWordID(props.scrollWordID);
        }
    },[props.scrollWordID]);

    useEffect(() => {
        const url = new URL(window.location.href);
        let word_id = url.searchParams.get('Word_ID');
        setScrollWordID(word_id);
    },[new URL(window.location.href).searchParams.get('Word_ID')]);

    useEffect(() => {
        if(!sortType) {
            setSortType('chrono_desc');
            setSortedFilteredList(props.filtered_list.sort((a,b) => (b.Word_ID - a.Word_ID)));
        }else{
            sortList(sortType);
        }
    },[props.filtered_list])

    const sortList = (type) => {
        setSortType(type);
        switch(type) {
            case 'alpha_asc': {
                setSortedFilteredList(props.filtered_list.sort((a,b) => {
                    const word_a = a.word.toLowerCase();
                    const word_b = b.word.toLowerCase();
                    if(word_a < word_b) {
                        return -1;
                    }
                    if(word_a > word_b) {
                        return 1;
                    }
                    return 0;
                }));
                break;
            }
            case 'alpha_desc': {
                setSortedFilteredList(props.filtered_list.sort((a,b) => {
                    const word_a = a.word.toLowerCase();
                    const word_b = b.word.toLowerCase();
                    if(word_a < word_b) {
                        return 1;
                    }
                    if(word_a > word_b) {
                        return -1;
                    }
                    return 0;
                }));
                break;
            }
            case 'chrono_asc': {
                setSortedFilteredList(props.filtered_list.sort((a,b) => (a.Word_ID - b.Word_ID)));
                break;
            }
            case 'chrono_desc': {
                setSortedFilteredList(props.filtered_list.sort((a,b) => (b.Word_ID - a.Word_ID)));
                break;
            }
            case 'location_asc': {
                setSortedFilteredList(props.filtered_list.sort((a,b) => (a.Bundesland_ID - b.Bundesland_ID)));
                break;
            }
            case 'location_desc': {
                setSortedFilteredList(props.filtered_list.sort((a,b) => (b.Bundesland_ID - a.Bundesland_ID)));
                break;
            }
            case 'user_asc': {
                setSortedFilteredList(props.filtered_list.sort((a,b) => {
                    const word_a = a.UserName.toLowerCase();
                    const word_b = b.UserName.toLowerCase();
                    if(word_a < word_b) {
                        return -1;
                    }
                    if(word_a > word_b) {
                        return 1;
                    }
                    return 0;
                }));
                break;
            }
            case 'user_desc': {
                setSortedFilteredList(props.filtered_list.sort((a,b) => {
                    const word_a = a.UserName.toLowerCase();
                    const word_b = b.UserName.toLowerCase();
                    if(word_a < word_b) {
                        return 1;
                    }
                    if(word_a > word_b) {
                        return -1;
                    }
                    return 0;
                }));
                break;
            }
            default:
        }
    }

    return (
        <div className={'list'}>
            <div className="list__options">
                {/*<div className="list__options__search">*/}
                {/*    <div className="list__options__search__icon list__options__search__icon__search">*/}
                {/*        <FontAwesomeIcon icon={'search'} />*/}
                {/*    </div>*/}
                {/*    <Input*/}
                {/*        type={'text'}*/}
                {/*        name={'search'}*/}
                {/*        placeholder={Translations.searchFor[props.language] + '...'}*/}
                {/*        onChange={(key, value) => setSearch(value)}*/}
                {/*        value={search}*/}
                {/*    />*/}
                {/*    <div className="list__options__search__icon list__options__search__icon__reset" onClick={() => setSearch('')}>*/}
                {/*        <FontAwesomeIcon icon={'times'} />*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="list__options__sort">
                    <div className={['alpha_asc', 'alpha_desc'].includes(sortType) ? "list__options__sort__option list__options__sort__option--active" : "list__options__sort__option"} onClick={() => sortList(sortType === 'alpha_asc' ? 'alpha_desc' : 'alpha_asc')}>
                        <FontAwesomeIcon icon={sortType === 'alpha_asc' ? 'sort-alpha-asc' : 'sort-alpha-desc'} />
                    </div>
                    <div className={['chrono_asc', 'chrono_desc'].includes(sortType) ? "list__options__sort__option list__options__sort__option--active" : "list__options__sort__option"} onClick={() => sortList(sortType === 'chrono_asc' ? 'chrono_desc' : 'chrono_asc')}>
                        <FontAwesomeIcon icon={sortType === 'chrono_asc' ? 'sort-numeric-asc' : 'sort-numeric-desc'} />
                    </div>
                    <div className={['location_asc', 'location_desc'].includes(sortType) ? "list__options__sort__option list__options__sort__option--active" : "list__options__sort__option"} onClick={() => sortList(sortType === 'location_asc' ? 'location_desc' : 'location_asc')}>
                        <FontAwesomeIcon icon={'map-marker-alt'} />
                        <FontAwesomeIcon icon={sortType === 'location_asc' ? 'sort-numeric-asc' : 'sort-numeric-desc'} />
                    </div>
                    <div className={['user_asc', 'user_desc'].includes(sortType) ? "list__options__sort__option list__options__sort__option--active" : "list__options__sort__option"} onClick={() => sortList(sortType === 'user_asc' ? 'user_desc' : 'user_asc')}>
                        <FontAwesomeIcon icon={'user'} />
                        <FontAwesomeIcon icon={sortType === 'user_asc' ? 'sort-numeric-asc' : 'sort-numeric-desc'} />
                    </div>
                </div>
            </div>
            {sortedFilteredList.map((item, index) => (
                <BAWEListElem key={index} item={item} scrollWordID={scrollWordID} socket={props.socket}/>
            ))}
        </div>
    )
}

BAWEListContainer.propTypes = {
    language: PropTypes.string,
    BAWEList: PropTypes.object,
}

const mapStateToProps = (state) => ({
    language: state.user.userData.Language,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BAWEListContainer)