import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatDateTime} from "../Base/Functions/Functions";
import React, {useState} from "react";
import {saveData} from "../Base/Functions/Functions";
import {response} from "../Base/Modal/ModalReducer";
import PropTypes from "prop-types";
import {completeCommentLike} from "./BAWEReducer";

const BAWECommentItem = (props) => {
    const [likeTriggered, setLikeTriggered] = useState(false);

    const likeComment = (comment_id) => {
        let fd = new FormData();
        let send_data = {
            Word_ID  : props.Word_ID,
            userID   : props.userID,
            commentID: comment_id,
        }
        fd.append('data', JSON.stringify(send_data));

        props.saveData({
            Query     : 'BAWE_like_comment',
            formData  : fd,
            title     : '',
            errorTitle: '',
            callBack  : completeCommentLike,
            response  : props.response,
            hidden    : true,
        })

        setLikeTriggered(true);
        setTimeout(() => {
            setLikeTriggered(false);
        }, 1000)
    }

    const completeCommentLike = (data) => {
        props.socket.send(JSON.stringify({
            type: 'BAWE_like_comment',
            data,
        }));
        props.completeCommentLike(data);
    }

    let isCommentLikedByUser = false;
    if(props.item.Comment_Likes && Object.keys(props.item.Comment_Likes).length > 0) {
        isCommentLikedByUser = Object.values(props.item.Comment_Likes).some(item => item.User_ID === props.userID);
    }

    return (
        <div className="comment_section__comments__elem"
             id={'comment_id_' + props.item.Comment_ID}>
            {(props.item.User_ID === props.userID || props.UserRights === 10) &&
                <div className="comment_section__comments__elem__delete_icon"
                     onClick={() => props.deleteComment(Number(props.item.Comment_ID))}>
                    <FontAwesomeIcon icon={'times'}/>
                </div>
            }
            <div className="comment_section__comments__elem__user">
                <div>
                    {props.userList[props.item.User_ID].PreName + ' ' + props.userList[props.item.User_ID].LastName}
                </div>
            </div>
            <div
                className="comment_section__comments__elem__comment">{props.item.Comment}
            </div>
            <div className="comment_section__comments__elem__comment_options">
                <div>
                    <div className={'comment_section__comments__elem__comment_options__comment_like__div'}>
                        <FontAwesomeIcon
                            className={likeTriggered ? "comment_section__comments__elem__comment_options__comment_like comment_section__comments__elem__comment_options__comment_like__animated" : isCommentLikedByUser ? "comment_section__comments__elem__comment_options__comment_like comment_section__comments__elem__comment_options__comment_like--liked" : "comment_section__comments__elem__comment_options__comment_like"}
                            icon={'heart'} onClick={() => likeComment(props.item.Comment_ID)}/>
                        <div>{props.item.Comment_Likes && Object.keys(props.item.Comment_Likes).length > 0 ? Object.keys(props.item.Comment_Likes).length : null}</div>
                    </div>
                </div>
                <div
                    className="comment_section__comments__elem__comment_options__datetime">{props.item.datetime ? formatDateTime(props.item.datetime, true) : ''}
                </div>
            </div>
        </div>
    )
}

BAWECommentItem.propTypes = {
    item               : PropTypes.object,
    userID             : PropTypes.number,
    UserRights         : PropTypes.number,
    deleteComment      : PropTypes.func,
    userList           : PropTypes.object,
    saveData           : PropTypes.func,
    response           : PropTypes.func,
    completeCommentLike: PropTypes.func,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
    saveData,
    response,
    completeCommentLike,
}

export default connect(mapStateToProps, mapDispatchToProps)(BAWECommentItem)