import React, {Component} from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import Translations from "../Translations";

class Footer extends Component {
    render() {
        return (
            <div className={'footer'}>
                <NavLink to={'/Impressum'}>
                    <div className="footer--elem">{Translations.impressum[this.props.userData.Language]}</div>
                </NavLink>
                <NavLink to={'/PrivacyPolicy'}>
                    <div className="footer--elem">{Translations.privacy_policy[this.props.userData.Language]}</div>
                </NavLink>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.userData,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);