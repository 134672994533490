import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatDateTime} from "../Base/Functions/Functions";
import Input from "../Base/Components/Input";
import Translations from "../Base/Translations";
import React, {useState} from "react";
import PropTypes from "prop-types";
import BAWECommentItem from "./BAWECommentItem";

const BAWECommentContainer = (props) => {

    return (
        <div
            className={props.commentSectionOpen ? "comment_section comment_section--opened" : "comment_section comment_section--closed"}>
            <div className="comment_section__comments" id={'comment_section_' + props.item.Word_ID}>
                {props.commentSectionOpen && props.item.Comments && Object.keys(props.item.Comments).length > 0 && props.sortedCommentsArray.map((item, index) => (
                    <BAWECommentItem
                        key={index}
                        item={item}
                        userID={props.userID}
                        UserRights={props.UserRights}
                        deleteComment={props.deleteComment}
                        userList={props.userList}
                        Word_ID={props.Word_ID}
                        socket={props.socket}
                    />
                ))}
            </div>
            <div className="comment_section__input">
                <Input
                    id={'comment_input' + props.item.Word_ID}
                    type={'text'}
                    name={'comment'}
                    label={''}
                    placeholder={Translations.writeAComment[props.userData.Language] + '...'}
                    onChange={props.handleCommentChange}
                    value={props.comment}
                    onKeyDown={props.handleCommentSubmit}
                />
                {props.commentSectionOpen &&
                    <div className="comment_section__input__icon"
                         onClick={() => props.handleCommentSubmit({ key: 'Enter' })}>
                        <FontAwesomeIcon icon={'paper-plane'}/>
                    </div>
                }
            </div>
        </div>
    )
}

BAWECommentContainer.propTypes = {
    commentSectionOpen : PropTypes.bool,
    item               : PropTypes.object,
    sortedCommentsArray: PropTypes.array,
    deleteComment      : PropTypes.func,
    handleCommentChange: PropTypes.func,
    comment            : PropTypes.string,
    handleCommentSubmit: PropTypes.func,
    userData           : PropTypes.object,
    userID             : PropTypes.number,
    UserRights         : PropTypes.number,
    userList           : PropTypes.object,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BAWECommentContainer)