import {connect} from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toggleLikeOverlay} from "./BAWEReducer";
import LikeOverlayItem from "./LikeOverlayItem";

const LikeOverlay = (props) => {
    const closeOverlay = () => {
        props.toggleLikeOverlay();
    }

    if (!props.userList || Object.keys(props.userList).length === 0) return null;

    return (
        <div className={'like_overlay'}>
            <div className="like_overlay__container">
                <div className="like_overlay__container__close_icon" onClick={closeOverlay}>
                    <FontAwesomeIcon icon={'times'}/>
                </div>
                <h2><FontAwesomeIcon icon={'thumbs-up'}/> Likes</h2>
                <div className="like_overlay__container__main">
                    <div className="like_overlay__container__main__list">
                        {props.like_overlay.list.map((item, index) => (
                            <LikeOverlayItem key={index} userID={item} userList={props.userList} />
                        ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

LikeOverlay.propTypes = {
    like_overlay     : PropTypes.object,
    toggleLikeOverlay: PropTypes.func,
}

const mapStateToProps = (state) => ({
    like_overlay: state.BAWE.like_overlay,
    userList    : state.user.userList,
})

const mapDispatchToProps = {
    toggleLikeOverlay
}

export default connect(mapStateToProps, mapDispatchToProps)(LikeOverlay)