import React from "react";

import {store} from "./configureStore";

import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";

import AppContent from "./AppContent";

import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faUser,
    faPlay,
    faPause,
    faVolumeMute,
    faVolumeOff,
    faVolumeLow,
    faVolumeHigh,
    faVolumeUp,
    faVolumeDown,
    faVolumeXmark,
    faShuffle,
    faForwardStep,
    faBackwardStep,
    faPlusCircle,
    faTimes,
    faHome,
    faInfo,
    faMusic,
    faHeart,
    faChevronLeft,
    faChevronCircleUp,
    faChevronDown,
    faGlobeEurope,
    faSignOutAlt,
    faWarning,
    faTrash,
    faRepeat,
    faIcons,
    faThumbsUp,
    faComment,
    faPaperPlane,
    faBook,
    faPlus,
    faPen,
    faSearch,
    faBell,
    faKey,
    faEye,
    faEyeSlash,
    faSortAlphaAsc,
    faSortAlphaDesc,
    faSortNumericAsc,
    faSortNumericDesc,
    faMapMarkerAlt,
    faChevronUp,
    faShareAlt,
} from '@fortawesome/free-solid-svg-icons';
import config from "./config";

library.add(
    faUser,
    faPlay,
    faPause,
    faVolumeMute,
    faVolumeOff,
    faVolumeLow,
    faVolumeHigh,
    faVolumeUp,
    faVolumeDown,
    faVolumeXmark,
    faShuffle,
    faForwardStep,
    faBackwardStep,
    faPlusCircle,
    faTimes,
    faHome,
    faInfo,
    faMusic,
    faHeart,
    faChevronLeft,
    faChevronCircleUp,
    faChevronDown,
    faGlobeEurope,
    faSignOutAlt,
    faWarning,
    faTrash,
    faRepeat,
    faIcons,
    faThumbsUp,
    faComment,
    faPaperPlane,
    faBook,
    faPlus,
    faPen,
    faSearch,
    faBell,
    faKey,
    faEye,
    faEyeSlash,
    faSortAlphaAsc,
    faSortAlphaDesc,
    faSortNumericAsc,
    faSortNumericDesc,
    faMapMarkerAlt,
    faChevronUp,
    faShareAlt,
)

class App extends React.Component {
    constructor(props) {
        super(props);
        this.socket = new WebSocket(config.wss);

        this.state = {
            wssData: null,
        };
    }

    componentDidMount() {
        this.socket.addEventListener('message', this.handleMessage);
    }

    componentWillUnmount() {
        this.socket.removeEventListener('message', this.handleMessage);
    }

    handleMessage = (event) => {
        const blob = event.data;
        const reader = new FileReader();

        reader.onload = (event) => {
            const receivedString = event.target.result;
            const data = JSON.parse(receivedString);
            this.setState({
                wssData: data,
            })
        };

        reader.readAsText(blob);
    };

    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <AppContent socket={this.socket} wssData={this.state.wssData}/>
                </BrowserRouter>
            </Provider>
        )
    }
}

export default App;