import {connect} from "react-redux";
import config from "../Base/config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import Input from "../Base/Components/Input";
import {formatDateTime} from "../Base/Functions/Functions";
import {saveData} from "../Base/Functions/Functions";
import {response} from "../Base/Modal/ModalReducer";
import PropTypes from "prop-types";
import {
    completeLike,
    completeNewComment,
    completeDeleteComment,
    completeAddDescription,
    completeDeleteDescription,
    completeDeleteWord,
    toggleLikeOverlay,
    togglePreventCommentScroll,
} from "./BAWEReducer";
import Translations from "../Base/Translations";
import BAWECommentContainer from "./BAWECommentContainer";

const BAWEListElem = (props) => {

    const [likeTriggered, setLikeTriggered] = useState(false);
    const [comment, setComment] = useState('');
    const [commentSectionOpen, setCommentSectionOpen] = useState(false);
    const [editDesc, setEditDesc] = useState(false);
    const [newDesc, setNewDesc] = useState('');

    useEffect(() => {
        setTimeout(() => {
            let target = document.getElementById(`word_${props.scrollWordID}`);
            if (target) {
                const yOffset = -50;
                target.scrollIntoView({
                    behavior      : 'smooth',
                    block         : 'start',
                    inline        : 'nearest',
                    inlineFragment: 'nearest',
                    blockCenter   : 0.5,
                    inlineCenter  : 0.5,
                    offsetTop     : yOffset
                });
                const url = new URL(window.location.href);
                const searchParams = new URLSearchParams(url.search);
                searchParams.delete('Word_ID');
                window.history.replaceState({}, document.title, `${url.pathname}${searchParams}`);
                if (Number(props.scrollWordID) === Number(props.item.Word_ID)) {
                    if (!commentSectionOpen) {
                        setCommentSectionOpen(true);
                    }
                }
            }
        }, 200)
    }, [props.scrollWordID, new URL(window.location.href).searchParams.get('Word_ID')])

    useEffect(() => {
        if (props.preventCommentScroll) {
            props.togglePreventCommentScroll(false);
        } else {
            const comment_section = document.getElementById('comment_section_' + props.item.Word_ID);
            if (comment_section) {
                comment_section.scrollTop = comment_section.scrollHeight;
            }
        }
    }, [props.item.Comments, commentSectionOpen])

    const handleLike = () => {
        let fd = new FormData();
        let send_data = {
            userID: props.userID,
            wordID: props.item.Word_ID,
        };
        fd.append('data', JSON.stringify(send_data));

        props.saveData({
            Query     : 'BAWE_like',
            formData  : fd,
            title     : '',
            errorTitle: '',
            callBack  : completeLike,
            response  : props.response,
            hidden    : true,
        })
        setLikeTriggered(true);
        setTimeout(() => {
            setLikeTriggered(false);
        }, 1000)
    }

    const completeLike = (data) => {
        props.socket.send(JSON.stringify({
            type: 'BAWE_Like',
            data,
        }));
        props.completeLike(data);
    }

    const handleCommentSection = () => {
        const comment_input = document.getElementById('comment_input' + props.item.Word_ID);
        setCommentSectionOpen(!commentSectionOpen);
        setTimeout(() => {
            comment_input.focus();
        }, 200);
    }

    const handleCommentChange = (key, value) => {
        const emoji_arr = [
            {
                before: ':)',
                after : '😀',
            },
            {
                before: ':D',
                after : '😆',
            },
            {
                before: ":'D",
                after : '😅',
            },
            {
                before: ':"D',
                after : '😂',
            },
            {
                before: ':*',
                after : '😘',
            },
            {
                before: '*-*',
                after : '😍',
            },
            {
                before: '-.-',
                after : '😔',
            },
            {
                before: ';P',
                after : '😜',
            },
            {
                before: ':P',
                after : '😛',
            },
            {
                before: '>.<',
                after : '😣',
            },
            {
                before: ':/',
                after : '😕',
            },
            {
                before: ':|',
                after : '😐',
            },
            {
                before: ':(',
                after : '😞',
            },
            {
                before: '*shit*',
                after : '💩',
            },
            {
                before: '*party*',
                after : '🥳',
            },
        ];

        for (let i in emoji_arr) {
            if (value.includes(emoji_arr[i].before)) {
                value = value.replace(emoji_arr[i].before, emoji_arr[i].after);
            }
        }
        setComment(value);
    }

    const handleCommentSubmit = (e) => {
        if ((e.key === 'Enter' || e.keyCode === 13 || e.code === 'Enter' || e.key === 'Return' || e.code === 'Return') && comment.trim() !== '') {
            let fd = new FormData();
            let send_data = {
                userID: props.userID,
                wordID: props.item.Word_ID,
                comment,
            };
            fd.append('data', JSON.stringify(send_data));

            props.saveData({
                Query     : 'BAWE_comment_new',
                formData  : fd,
                title     : '',
                errorTitle: '',
                callBack  : completeNewComment,
                response  : props.response,
                hidden    : true,
            })
            setComment('');
        }
    }

    const completeNewComment = (data) => {
        props.socket.send(JSON.stringify({
            type: 'BAWE_Comment_new',
            data
        }));
        props.completeNewComment(data);
    }

    const deleteComment = (commentID) => {
        let fd = new FormData();
        let send_data = {
            wordID: props.item.Word_ID,
            commentID,
        };
        fd.append('data', JSON.stringify(send_data));

        props.saveData({
            Query     : 'BAWE_comment_delete',
            formData  : fd,
            title     : '',
            errorTitle: '',
            callBack  : completeDeleteComment,
            response  : props.response,
            hidden    : true,
        })
    }

    const completeDeleteComment = (data) => {
        props.socket.send(JSON.stringify({
            type: 'BAWE_Comment_delete',
            data
        }));
        props.completeDeleteComment(data);
    }

    const addNewDescription = () => {
        setEditDesc(!editDesc);
    }

    const handleDescriptionSubmit = (e) => {
        if (e.key === 'Enter') {
            let fd = new FormData();
            let send_data = {
                wordID: props.item.Word_ID,
                userID: props.userID,
                desc  : newDesc,
            };
            fd.append('data', JSON.stringify(send_data));

            props.saveData({
                Query     : 'BAWE_add_description',
                formData  : fd,
                title     : '',
                errorTitle: '',
                callBack  : completeAddDescription,
                response  : props.response,
                hidden    : true,
            })
            setNewDesc('');
        }
    }

    const completeAddDescription = (data) => {
        props.socket.send(JSON.stringify({
            type: 'BAWE_add_description',
            data,
        }))
        props.completeAddDescription(data);
    }

    const deleteDescription = (desc_id) => {
        let fd = new FormData();
        let send_data = {
            wordID: props.item.Word_ID,
            desc_id,
        };
        fd.append('data', JSON.stringify(send_data));

        props.saveData({
            Query     : 'BAWE_delete_description',
            formData  : fd,
            title     : '',
            errorTitle: '',
            callBack  : completeDeleteDescription,
            response  : props.response,
            hidden    : true,
        })
    }

    const completeDeleteDescription = (data) => {
        props.socket.send(JSON.stringify({
            type: 'BAWE_delete_description',
            data,
        }))
        props.completeDeleteDescription(data);
    }

    const deleteWord = () => {
        let fd = new FormData();
        let send_data = {
            wordID: props.item.Word_ID,
        };
        fd.append('data', JSON.stringify(send_data));

        props.saveData({
            Query     : 'BAWE_delete_word',
            formData  : fd,
            title     : '',
            errorTitle: '',
            callBack  : completeDeleteWord,
            response  : props.response,
            hidden    : true,
        })
    }

    const completeDeleteWord = (data) => {
        setEditDesc(false);
        props.socket.send(JSON.stringify({
            type: 'BAWE_delete_word',
            data,
        }));
        props.completeDeleteWord(data);
    }

    if (!props.userList || !props.userList || !props.item || !props.userList[props.item.User_ID]) return null;

    let isWordLikedByUser = false;
    if (props.item.Likes && Object.keys(props.item.Likes).length > 0) {
        isWordLikedByUser = Object.values(props.item.Likes).some(item => item.User_ID === props.userID);
    }

    const sortedCommentsArray = props.item.Comments && Object.keys(props.item.Comments).length > 0 && Object.keys(props.item.Comments).map((elem) => (props.item.Comments[elem]));

    const bundesland_desc_exists = () => {
        for (let i in props.item.Description) {
            if (props.userList[props.item.Description[i].User_ID].Bundesland_ID === props.userData.Bundesland_ID) {
                return true;
            }
        }
        return false;
    }

    let user_likes_preview_arr = [];
    let user_likes_arr = [];

    if (props.item.Likes) {
        let x = 0;
        for (let i in props.item.Likes) {
            if (props.userList && props.item.Likes[i] && props.userList[props.item.Likes[i].User_ID]) {
                let like_user_name = props.userList[props.item.Likes[i].User_ID].PreName + ' ' + props.userList[props.item.Likes[i].User_ID].LastName;
                if (x <= 3) {
                    if (x === 3) {
                        let arr_len = Object.keys(props.item.Likes).length;
                        user_likes_preview_arr.push('+' + (Number(arr_len) - x) + ' ' + Translations.andMoreUsers[props.userData.Language]);
                    } else {
                        user_likes_preview_arr.push(like_user_name)
                        x++;
                    }
                }
                user_likes_arr.push(props.item.Likes[i].User_ID);
            }
        }
    }

    const toggleLikeOverlay = () => {
        props.toggleLikeOverlay({ user_likes_arr });
    }

    return (
        <div className="list__scroll_div" id={'word_' + props.item.Word_ID}>
            <div className={user_likes_arr.length > 0 ? "list__elem" : "list__elem list__elem--no_like_section"}>
                <div className={'list__elem__header'}>
                    <div className="list__elem__header__entry">
                        <div className="list__elem__header__entry__word">
                            {editDesc && (props.item.User_ID === props.userID || props.UserRights === 10) &&
                                <div className="list__elem__header__entry__word__del_icon" onClick={deleteWord}>
                                    <FontAwesomeIcon icon={'times'}/>
                                </div>
                            }
                            <div className='list__elem__header__entry__word__word'>
                                {props.item.word}
                            </div>
                            {props.item.bad === 1 &&
                                <img src={config.baseURL + config.imageURL + '/swear_icon.png'} alt="bad language"
                                     width={25}/>
                            }
                        </div>
                        {props.item.Description && Object.keys(props.item.Description).length > 0 && Object.keys(props.item.Description).map((item) => (
                            <div className="list__elem__header__entry__description_container">
                                <div className={'list__elem__header__entry__description'}>
                                    {editDesc && (props.item.User_ID === props.userID || props.item.Description[item].User_ID === props.userID || props.UserRights === 10) &&
                                        <div className="list__elem__header__entry__description__del_icon"
                                             onClick={() => deleteDescription(item)}>
                                            <FontAwesomeIcon icon={'times'}/>
                                        </div>
                                    }
                                    <div className={'list__elem__header__entry__description__text'}>
                                        {props.item.Description[item].Description}
                                    </div>
                                </div>
                                <div className="list__elem__header__entry__description__user">
                                    <div>
                                        {props.userList[props.item.Description[item].User_ID].PreName + ' ' + props.userList[props.item.Description[item].User_ID].LastName}
                                    </div>
                                    <img width={7}
                                         src={config.baseURL + config.imageURL + '/AT_Flags/' + props.userList[props.item.Description[item].User_ID].Flag_dir}
                                         alt={props.userList[props.item.Description[item].User_ID].Bundesland + ' Flagge'}/>
                                </div>
                            </div>
                        ))}
                        {props.userData.Bundesland_ID &&
                            <div className="list__elem__header__entry__description__add_button"
                                 onClick={addNewDescription}>
                                <FontAwesomeIcon icon={'pen'}/>
                            </div>
                        }
                        {editDesc &&
                            // editDesc && !bundesland_desc_exists() &&
                            <div className="list__elem__header__entry__description__addNew">
                                <Input
                                    type={'text'}
                                    name={'new_desc'}
                                    label={''}
                                    placeholder={Translations.newDescription[props.userData.Language] + '...'}
                                    onChange={(key, value) => setNewDesc(value)}
                                    value={newDesc}
                                    onKeyDown={handleDescriptionSubmit}
                                />
                                <div className="list__elem__header__entry__description__addNew__add_icon"
                                     onClick={() => handleDescriptionSubmit({ key: 'Enter' })}>
                                    <FontAwesomeIcon icon={'plus'}/>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="list__elem__header__user">
                        <div>{props.userList[props.item.User_ID].PreName + ' ' + props.userList[props.item.User_ID].LastName}</div>
                        <div>
                            <img width={10}
                                 src={config.baseURL + config.imageURL + '/AT_Flags/' + props.userList[props.item.User_ID].Flag_dir}
                                 alt={props.userList[props.item.User_ID].Bundesland + ' Flagge'}/>
                        </div>
                    </div>
                </div>
                <div className="list__elem__options">
                    <div className={"list__elem__options__option"} onClick={props.userData.access !== 0 && handleLike}>
                        <div
                            className={likeTriggered ? "list__elem__options__option--like_animated" : props.userData.access === 0 ? "list__elem__options__option--like--restricted" : isWordLikedByUser ? "list__elem__options__option--like--liked" : "list__elem__options__option--like--unliked"}>
                            <FontAwesomeIcon icon={'thumbs-up'}/>
                        </div>
                        <div
                            className={'list__elem__options__option__count--like'}>{props.item.Likes && Object.keys(props.item.Likes).length > 0 && Object.keys(props.item.Likes).length}
                        </div>
                    </div>
                    <div className="list__elem__options__option"
                         onClick={props.userData.access !== 0 && handleCommentSection}>
                        <div
                            className={props.userData.access === 0 ? "list__elem__options__option--comment--restricted" : "list__elem__options__option--comment"}>
                            <FontAwesomeIcon icon={'comment'}/>
                        </div>
                        <div
                            className={'list__elem__options__option__count--comment'}>{props.item.Comments && Object.keys(props.item.Comments).length > 0 && Object.keys(props.item.Comments).length}</div>
                    </div>
                </div>
            </div>
            {user_likes_arr.length > 0 &&
                <div className="list__elem__user_likes_section" onClick={toggleLikeOverlay}>
                    <FontAwesomeIcon icon={'thumbs-up'}/>
                    {Translations.liked_by[props.userData.Language]} {user_likes_preview_arr.join(', ')}
                </div>
            }
            <BAWECommentContainer
                commentSectionOpen={commentSectionOpen}
                item={props.item}
                sortedCommentsArray={sortedCommentsArray}
                deleteComment={deleteComment}
                handleCommentChange={handleCommentChange}
                comment={comment}
                handleCommentSubmit={handleCommentSubmit}
                userData={props.userData}
                userID={props.userID}
                UserRights={props.UserRights}
                userList={props.userList}
                Word_ID={props.item.Word_ID}
                socket={props.socket}
            />
        </div>
    )
}

BAWEListElem.propTypes = {
    saveData                  : PropTypes.func,
    response                  : PropTypes.func,
    userID                    : PropTypes.number,
    item                      : PropTypes.object,
    userList                  : PropTypes.object,
    completeLike              : PropTypes.func,
    completeNewComment        : PropTypes.func,
    completeDeleteComment     : PropTypes.func,
    completeDeleteDescription : PropTypes.func,
    completeDeleteWord        : PropTypes.func,
    toggleLikeOverlay         : PropTypes.func,
    preventCommentScroll      : PropTypes.bool,
    togglePreventCommentScroll: PropTypes.func,
}

const mapStateToProps = (state) => ({
    userID              : state.user.userData.userID,
    UserRights          : state.user.userData.UserRights,
    userList            : state.user.userList,
    userData            : state.user.userData,
    preventCommentScroll: state.BAWE.preventCommentScroll,
})

const mapDispatchToProps = {
    saveData,
    response,
    completeLike,
    completeNewComment,
    completeDeleteComment,
    completeAddDescription,
    completeDeleteDescription,
    completeDeleteWord,
    toggleLikeOverlay,
    togglePreventCommentScroll,
}

export default connect(mapStateToProps, mapDispatchToProps)(BAWEListElem);