import {connect} from "react-redux";
import Input from "../Base/Components/Input";
import React from "react";
import {useState} from "react";
import {saveData} from "../Base/Functions/Functions";
import {response} from "../Base/Modal/ModalReducer";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const UserOverviewChangePassword = (props) => {
    const [oldPassword, setOldPassword] = useState('');
    const [toggleOldPassword, setToggleOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [toggleNewPassword, setToggleNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [toggleRepeatNewPassword, setToggleRepeatNewPassword] = useState('');

    const [warning, setWarning] = useState(false);
    const [warningText, setWarningText] = useState('');

    const changePassword = () => {
        if (newPassword === repeatNewPassword) {
            let fd = new FormData();
            let send_data = {
                userID       : props.userData.userID,
                old_pw       : oldPassword,
                new_pw       : newPassword,
                repeat_new_pw: repeatNewPassword,
            }
            fd.append('data', JSON.stringify(send_data));

            props.saveData({
                Query     : 'User_change_password',
                formData  : fd,
                title     : '',
                errorTitle: '',
                callBack  : completeChangePassword,
                response  : props.response,
                language  : props.userData.Language,
            })
        } else {
            setWarning(true);
            setWarningText('Passwörter stimmen nicht überein!');
        }
    }

    const completeChangePassword = () => {
        // console.log(data);
    }

    return (
        <div className="user_overview--data__change_pw">
            <div className="password_input">
                <Input
                    type={toggleOldPassword ? 'text' : 'password'}
                    name={'old_pw'}
                    label={'Altes Passwort'}
                    placeholder={'Altes Passwort'}
                    onChange={(key, value) => setOldPassword(value)}
                    value={oldPassword}
                />
                <div className="password_input__icon" onClick={() => setToggleOldPassword(!toggleOldPassword)}>
                    <FontAwesomeIcon icon={toggleOldPassword ? 'eye-slash' : 'eye'} />
                </div>
            </div>
            <div className="password_input">
                <Input
                    type={toggleNewPassword ? 'text' : 'password'}
                    name={'new_pw'}
                    label={'Neues Passwort'}
                    placeholder={'Neues Passwort'}
                    onChange={(key, value) => setNewPassword(value)}
                    value={newPassword}
                />
                <div className="password_input__icon" onClick={() => setToggleNewPassword(!toggleNewPassword)}>
                    <FontAwesomeIcon icon={toggleNewPassword ? 'eye-slash' : 'eye'} />
                </div>
            </div>
            <div className="password_input">
                <Input
                    type={toggleRepeatNewPassword ? 'text' : 'password'}
                    name={'repeat_new_pw'}
                    label={'Neues Password wiederholen'}
                    placeholder={'Neues Password wiederholen'}
                    onChange={(key, value) => setRepeatNewPassword(value)}
                    value={repeatNewPassword}
                />
                <div className="password_input__icon" onClick={() => setToggleRepeatNewPassword(!toggleRepeatNewPassword)}>
                    <FontAwesomeIcon icon={toggleRepeatNewPassword ? 'eye-slash' : 'eye'} />
                </div>
            </div>
            {warning &&
                <div className="user_overview--data__change_pw__warning">
                    {warningText}
                </div>
            }
            <div className="user_overview--data__button user_overview--data__button--orangered"
                 onClick={changePassword}>
                <div>
                    Passwort ändern
                </div>
            </div>
        </div>
    )
}

UserOverviewChangePassword.propTypes = {
    saveData: PropTypes.func,
    response: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
    saveData,
    response,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserOverviewChangePassword);