import {createReducer,createAction} from "@reduxjs/toolkit";

//actions

export const toggleAlert = createAction('TOGGLE_ALERT');
export const closeAlert = createAction('CLOSE_ALERT');

//initialState

export const alert = {
    open: false,
    header: '',
    text: '',
}

//reducer

const AlertReducer = createReducer(alert, builder => {
    builder
        .addCase(toggleAlert, (state,action) => {
            state.open = !state.open;
            state.header = action.payload.header;
            state.text = action.payload.text;
        })
        .addCase(closeAlert, (state) => {
            state.open = false;
            state.header = '';
            state.text = '';
        })
})

export default AlertReducer;